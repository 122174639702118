/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { adminApi, adminAuthApi } from './api/admin/admin.api';
import { clientsApi } from './api/clients/clients.api';
import { communicationApi } from './api/communication/communication.api';
import { emailActivityApi } from './api/email-activity/email-activity.api';
import { feedbackApi } from './api/feedback/feedback.api';
import { integrationsApi } from './api/integrations/integrations.api';
import { loanOfficerApi } from './api/loan-officer/loan-officer.api';
import { marketingCollateralApi } from './api/marketing/marketing.api';
import { partnerDevelopmentManagerApi } from './api/partner-development-manager/partner-development-manager.api';
import { pushNotificationsApi } from './api/push-notifications/push-notifications.api';
import { realEstateAgentApi } from './api/real-estate-agent/real-estate-agent.api';
import { settingsApi } from './api/settings/settings.api';
import { templatesApiWithApiKey, templatesApiWithBearerToken } from './api/templates/templates.api';
import { textMessageActivityApi } from './api/text-activity/text-activity.api';

import announcementsReducer from './slices/announcements.slice';
import authReducer from './slices/auth.slice';
import featureFlagReducer from './slices/feature-flag.slice';
import landingPageReducer from './slices/landing-page.slice';
import layoutReducer from './slices/layout.slice';
import loanOfficersReducer from './slices/loan-officers.slice';
import loansReducer from './slices/loans.slice';
import notificationFilterIDReducer from './slices/notification/notification-filter.slice';
import notificationReducer from './slices/notification/notification.slice';
import realEstateAgentReducer from './slices/real-estate-agent.slice';
import referClientReducer from './slices/refer-client.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        [adminApi.reducerPath]: adminApi.reducer,
        [adminAuthApi.reducerPath]: adminAuthApi.reducer,
        [clientsApi.reducerPath]: clientsApi.reducer,
        [communicationApi.reducerPath]: communicationApi.reducer,
        [emailActivityApi.reducerPath]: emailActivityApi.reducer,
        [feedbackApi.reducerPath]: feedbackApi.reducer,
        [integrationsApi.reducerPath]: integrationsApi.reducer,
        [loanOfficerApi.reducerPath]: loanOfficerApi.reducer,
        [marketingCollateralApi.reducerPath]: marketingCollateralApi.reducer,
        [partnerDevelopmentManagerApi.reducerPath]: partnerDevelopmentManagerApi.reducer,
        [pushNotificationsApi.reducerPath]: pushNotificationsApi.reducer,
        [realEstateAgentApi.reducerPath]: realEstateAgentApi.reducer,
        [settingsApi.reducerPath]: settingsApi.reducer,
        [templatesApiWithApiKey.reducerPath]: templatesApiWithApiKey.reducer,
        [templatesApiWithBearerToken.reducerPath]: templatesApiWithBearerToken.reducer,
        [textMessageActivityApi.reducerPath]: textMessageActivityApi.reducer,
        landingPage: landingPageReducer,
        layout: layoutReducer,
        loans: loansReducer,
        notification: notificationReducer,
        notificationFilter: notificationFilterIDReducer,
        loanOfficers: loanOfficersReducer,
        realEstateAgent: realEstateAgentReducer,
        featureFlag: featureFlagReducer,
        announcements: announcementsReducer,
        referClient: referClientReducer,
    },
    middleware: (getDefaultMiddleware): any =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat([
            adminApi.middleware,
            adminAuthApi.middleware,
            clientsApi.middleware,
            communicationApi.middleware,
            emailActivityApi.middleware,
            feedbackApi.middleware,
            integrationsApi.middleware,
            loanOfficerApi.middleware,
            marketingCollateralApi.middleware,
            partnerDevelopmentManagerApi.middleware,
            pushNotificationsApi.middleware,
            realEstateAgentApi.middleware,
            settingsApi.middleware,
            templatesApiWithApiKey.middleware,
            templatesApiWithBearerToken.middleware,
            textMessageActivityApi.middleware,
        ]),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
