import { createApi } from '@reduxjs/toolkit/query/react';
import { getRealEstateAgentId } from 'app/services';
import { httpMethod } from 'app/services/_httpClient';
import { toasterSuccess, toasterError } from 'app/shared/helpers';
import { NAF_CONNECT_API_BASE_URL } from 'app/shared/helpers/settings.helper';
import { axiosBaseApiQuery } from '../axiosBaseQuery';
import { AddFeedbackRequest } from './models';

export const feedbackApi = createApi({
    reducerPath: 'referral-partner-feedback-api',
    baseQuery: axiosBaseApiQuery({ baseUrl: NAF_CONNECT_API_BASE_URL }),
    endpoints: (builder) => ({
        addFeedback: builder.mutation<boolean, AddFeedbackRequest>({
            query: (data) => ({
                url: `/realEstateAgents/${getRealEstateAgentId()}/feedback`,
                method: httpMethod.Post,
                data,
            }),
            transformResponse: (response: { data: boolean }) => response.data,
            async onQueryStarted(arg, { queryFulfilled }) {
                const { data: success } = await queryFulfilled;

                if (success) {
                    toasterSuccess('Success');
                } else {
                    toasterError('Error');
                }
            },
        }),
    }),
});

export const { useAddFeedbackMutation } = feedbackApi;
