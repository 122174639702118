import { apiRequest, httpMethod } from '../_httpClient';
import { ServiceResponse } from '../_httpClient/models/http-client.model';
import { SearchLoanOfficersResponse } from './models';

export const searchLoanOfficers = (
    stateLicensed: string,
    searchInput: string,
    includePreferredLoanOfficers: boolean
): ServiceResponse<SearchLoanOfficersResponse> => {
    const endpointUrl = `/loanOfficers?state=${stateLicensed}&nameOrNmls=${searchInput}&includePreferredLoanOfficers=${includePreferredLoanOfficers}`;

    return apiRequest<null, SearchLoanOfficersResponse>(endpointUrl, httpMethod.Get, null).then(
        (response) => {
            if ('isAxiosError' in response) return null;

            return response;
        }
    );
};
