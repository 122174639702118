import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { Address, StateLicense } from 'app/shared/models';

interface ProfileRequiredFieldsProps {
    firstName: string;
    lastName: string;
    emailAddress: string;
    cellPhoneNumber: string;
    photoUrl: string;
    company: string;
    companyLogoUrl: string;
    companyAddress: Address;
    personalMailingAddress: Address;
    stateLicenses: StateLicense[];
}
interface RealEstateAgentSlice {
    missingFields: Map<string, string[]>;
    isLoading: boolean;
}

const initialState: RealEstateAgentSlice = {
    missingFields: new Map<string, string[]>(),
    isLoading: true,
};

function createMissingFieldMap(fields: ProfileRequiredFieldsProps): RealEstateAgentSlice {
    const { personalMailingAddress, company, companyAddress } = fields;
    const missingFieldsMap = new Map<string, string[]>();
    if (!fields.photoUrl) {
        missingFieldsMap.set('Profile Picture', []);
    }

    if (!fields.companyLogoUrl) {
        missingFieldsMap.set('Company Logo', []);
    }

    if (!fields.stateLicenses || fields.stateLicenses.length < 1) {
        missingFieldsMap.set('Real Estate License', []);
    }

    const mailingAddressDisplayNames = [];
    if (!personalMailingAddress?.address1) {
        mailingAddressDisplayNames.push('Mailing Address');
    }
    if (!personalMailingAddress?.city) {
        mailingAddressDisplayNames.push('City');
    }
    if (!personalMailingAddress?.state) {
        mailingAddressDisplayNames.push('State');
    }
    if (!personalMailingAddress?.zipCode) {
        mailingAddressDisplayNames.push('Zipcode');
    }

    if (mailingAddressDisplayNames.length > 0) {
        missingFieldsMap.set('Mailing Address', mailingAddressDisplayNames);
    }

    const companyDisplayNames = [];
    if (!company) {
        companyDisplayNames.push('Company Name');
    }
    if (!companyAddress?.address1) {
        companyDisplayNames.push('Company Address');
    }
    if (!companyAddress?.city) {
        companyDisplayNames.push('City');
    }
    if (!companyAddress?.state) {
        companyDisplayNames.push('State');
    }
    if (!companyAddress?.zipCode) {
        companyDisplayNames.push('Zipcode');
    }

    if (companyDisplayNames.length > 0) {
        missingFieldsMap.set('Company Information', companyDisplayNames);
    }

    const profileDisplayNames = [];
    if (!fields?.emailAddress) {
        profileDisplayNames.push('Email Address');
    }
    if (!fields?.firstName) {
        profileDisplayNames.push('First Name');
    }
    if (!fields?.lastName) {
        profileDisplayNames.push('Last Name');
    }
    if (!fields?.cellPhoneNumber) {
        profileDisplayNames.push('Cell Phone Number');
    }

    if (profileDisplayNames.length > 0) {
        missingFieldsMap.set('Personal Information', profileDisplayNames);
    }

    return { missingFields: missingFieldsMap, isLoading: false };
}

export const realEstateAgentSlice = createSlice({
    name: 'realEstateAgent',
    initialState,
    reducers: {
        setIsLoading: (state) => {
            state.isLoading = true;
        },
        setMissingFields: (state, action) => {
            const { missingFields, isLoading } = createMissingFieldMap(action.payload.payload);
            state.missingFields = missingFields;
            state.isLoading = isLoading;
        },
    },
});

export const { setMissingFields, setIsLoading } = realEstateAgentSlice.actions;
export const missingFieldsSelector = (state: RootState): Map<string, string[]> =>
    state.realEstateAgent.missingFields;
export const isMissingFieldsLoadingSelector = (state: RootState): boolean =>
    state.realEstateAgent.isLoading;

export default realEstateAgentSlice.reducer;
