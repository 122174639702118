export enum UIActionEnum {
    Signup,
    Login,
    ClientReferral,
    ClientStatus,
}

export type VendorAction = 'referral' | 'status' | 'clients';

export interface UIAction {
    action: UIActionEnum;
    actionUrl?: string;
}

export interface ClientReferralAction extends UIAction {
    action: UIActionEnum.ClientReferral;
    leadFirstName?: string;
    leadLastName?: string;
    leadEmailAddress?: string;
    leadAddress1?: string;
    leadAddress2?: string;
    leadZipCode?: string;
    leadStateCode?: string;
    leadPhoneNumber?: string;
}

export interface LoginAction extends UIAction {
    action: UIActionEnum.Login;
    agentEmailAddress?: string;
}

export interface SignupAction extends UIAction {
    action: UIActionEnum.Signup;
    agentFirstName?: string;
    agentLastName?: string;
    agentEmailAddress?: string;
    agentStateCode?: string;
    agentPhoneNumber?: string;
    nafHomesId?: number;
}

export interface ClientStatusAction extends UIAction {
    action: UIActionEnum.ClientStatus;
}

export type Action = SignupAction | LoginAction | ClientReferralAction | ClientStatusAction;

export interface UIActionsResponse {
    actions: Action[];
}

export interface VendorRequest {
    token: string;
}

export type VendorResponse = UIActionsResponse;
