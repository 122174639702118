import { NAF_CONNECT_API_BASE_URL } from 'app/shared/helpers/settings.helper';
import { axiosBaseApiQuery } from 'app/store/api/axiosBaseQuery';
import { MarketingCollateralFlyer } from './models';
import { getRealEstateAgentId, httpMethod } from 'app/services/_httpClient';
import { GetMarketingCollateralByIdRequest } from './models/get-marketing-collateral-by-id-request.model';
import { CreateFlyerRequest } from './models/create-flyer-request.model';
import { createApi } from '@reduxjs/toolkit/query/react';

export const marketingCollateralApi = createApi({
    reducerPath: 'marketingCollateral-api',
    baseQuery: axiosBaseApiQuery({
        baseUrl: `${NAF_CONNECT_API_BASE_URL}/realEstateAgents/`,
    }),
    endpoints: (builder) => ({
        getMarketingCollateral: builder.query<Array<MarketingCollateralFlyer> | null, void>({
            query: () => ({
                url: `${getRealEstateAgentId()}/marketingCollaterals`,
                method: httpMethod.Get,
            }),
            transformResponse: (response: { data: Array<MarketingCollateralFlyer> }) =>
                response.data,
        }),
        getMarketingCollateralById: builder.query<
            MarketingCollateralFlyer,
            GetMarketingCollateralByIdRequest
        >({
            query: ({ collateralTemplateId }) => ({
                url: `${getRealEstateAgentId()}/marketingCollaterals/${collateralTemplateId}`,
                method: httpMethod.Get,
            }),
            transformResponse: (response: { data: MarketingCollateralFlyer }) => response.data,
        }),
        createMarketingCollateralFlyer: builder.mutation<string, CreateFlyerRequest>({
            query: (data) => ({
                url: `${getRealEstateAgentId()}/marketingCollaterals/`,
                method: httpMethod.Post,
                data,
            }),
            transformResponse: (response: { data: string }) => response.data,
        }),
    }),
});

export const {
    useGetMarketingCollateralQuery,
    useGetMarketingCollateralByIdQuery,
    useCreateMarketingCollateralFlyerMutation,
} = marketingCollateralApi;
