import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AsyncRequestStatus } from 'app/shared/enums';
import { NotificationType, Notification } from 'app/shared/models';

import {
    getAllNotification,
    dismissAllNotification,
    dismissOneNotification,
} from 'app/services/notification/notification.service';

import { RootState } from '../../store';

export interface NotificationState {
    notificationTypes: NotificationType[];
    notifications: Notification[];
    statusGetNotificationList: AsyncRequestStatus;
    statusDismissAllNotification: AsyncRequestStatus;
    statusDismissOneNotification: AsyncRequestStatus;
}

const initialState: NotificationState = {
    notificationTypes: [],
    notifications: [],
    statusGetNotificationList: AsyncRequestStatus.Idle,
    statusDismissAllNotification: AsyncRequestStatus.Idle,
    statusDismissOneNotification: AsyncRequestStatus.Idle,
};

export const getAllNotificationAsync = createAsyncThunk(
    'notification/getAllNotification',
    async () => {
        // calling service
        const response = await getAllNotification();
        return response;
    }
);

export const dismissAllNotificationAsync = createAsyncThunk(
    'notification/dismissAllNotification',
    async () => {
        // calling service
        const response = await dismissAllNotification();
        return response;
    }
);

export const dismissOneNotificationAsync = createAsyncThunk(
    'notification/dismissOneNotification',
    async (id: string) => {
        // calling service
        const response = await dismissOneNotification(id);
        return response;
    }
);

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get List of All Notification
            .addCase(getAllNotificationAsync.pending, (state) => {
                state.statusGetNotificationList = AsyncRequestStatus.Pending;
            })
            .addCase(getAllNotificationAsync.fulfilled, (state, action) => {
                state.statusGetNotificationList = AsyncRequestStatus.Fulfilled;

                if (action.payload) {
                    state.notifications = action.payload.data?.data?.notifications || [];
                    state.notificationTypes = action.payload.data?.data?.notificationTypes || [];
                }
            })
            .addCase(getAllNotificationAsync.rejected, (state) => {
                state.statusGetNotificationList = AsyncRequestStatus.Rejected;
            })
            // Dismiss All Notification
            .addCase(dismissAllNotificationAsync.pending, (state) => {
                state.statusDismissAllNotification = AsyncRequestStatus.Pending;
            })
            .addCase(dismissAllNotificationAsync.fulfilled, (state) => {
                state.statusDismissAllNotification = AsyncRequestStatus.Fulfilled;
            })
            .addCase(dismissAllNotificationAsync.rejected, (state) => {
                state.statusDismissAllNotification = AsyncRequestStatus.Rejected;
            })
            // Dismiss One Notification
            .addCase(dismissOneNotificationAsync.pending, (state) => {
                state.statusDismissOneNotification = AsyncRequestStatus.Pending;
            })
            .addCase(dismissOneNotificationAsync.fulfilled, (state) => {
                state.statusDismissOneNotification = AsyncRequestStatus.Fulfilled;
            })
            .addCase(dismissOneNotificationAsync.rejected, (state) => {
                state.statusDismissOneNotification = AsyncRequestStatus.Rejected;
            });
    },
});

export const notificationFilterList = (state: RootState): NotificationType[] =>
    state.notification.notificationTypes;
export const notificationDataList = (state: RootState): Notification[] =>
    state.notification.notifications;

export const statusGetNotificationListSelector = (state: RootState): AsyncRequestStatus =>
    state.notification.statusGetNotificationList;
export const statusDismissAllNotificationSelector = (state: RootState): AsyncRequestStatus =>
    state.notification.statusDismissAllNotification;
export const statusDismissOneNotificationSelector = (state: RootState): AsyncRequestStatus =>
    state.notification.statusDismissOneNotification;

export default notificationSlice.reducer;
