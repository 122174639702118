import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface ReferClientState {
    leadDetails: {
        leadFirstName?: string;
        leadLastName?: string;
        leadEmailAddress?: string;
        leadAddress1?: string;
        leadAddress2?: string;
        leadZipCode?: string;
        leadStateCode?: string;
        leadPhoneNumber?: string;
    };
}

const initialState: ReferClientState = {
    leadDetails: {
        leadFirstName: '',
        leadLastName: '',
        leadEmailAddress: '',
        leadAddress1: '',
        leadAddress2: '',
        leadZipCode: '',
        leadStateCode: '',
        leadPhoneNumber: '',
    },
};

export const referClientSlice = createSlice({
    name: 'referClient',
    initialState,
    reducers: {
        setLeadDetails(state, action) {
            state.leadDetails = action.payload;
        },
    },
});

export const leadDetailsSelector = (state: RootState): ReferClientState['leadDetails'] =>
    state.referClient.leadDetails;

export const { setLeadDetails } = referClientSlice.actions;
export default referClientSlice.reducer;
