import { AutocompletePrediction } from 'app/shared/models';
import { httpMethod, httpRequest } from '../_httpClient';
import { ServiceResponse } from '../_httpClient/models/http-client.model';
import { GetAddressDetailsResponse } from './models/get-address-details-response.model';

export const addressesAutocomplete = (input: string): ServiceResponse<AutocompletePrediction[]> => {
    const endpointUrl = `/addresses?input=${input}`;

    return httpRequest<null, AutocompletePrediction[]>(endpointUrl, httpMethod.Get).then(
        (response) => {
            if ('isAxiosError' in response) return null;

            return response;
        }
    );
};

export const getAddressDetails = (placeId: string): ServiceResponse<GetAddressDetailsResponse> => {
    const endpointUrl = `/addresses/${placeId}/details`;

    return httpRequest<null, GetAddressDetailsResponse>(endpointUrl, httpMethod.Get).then(
        (response) => {
            if ('isAxiosError' in response) return null;
            return response;
        }
    );
};
