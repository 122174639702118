import { AnnouncementsTokenEnum, AsyncRequestStatus } from 'app/shared/enums';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Announcement } from 'app/services/announcements/models';
import { getAnnouncements } from 'app/services';
import { formatDate, replaceAllTokens } from 'app/shared/helpers';

export interface AnnouncementsState {
    announcements: Array<Announcement>;
    getAnnouncements: AsyncRequestStatus;
}

const initialState: AnnouncementsState = {
    announcements: Array<Announcement>(),
    getAnnouncements: AsyncRequestStatus.Idle,
};

export const GetAnnouncementsAsync = createAsyncThunk(
    'announcements/GetAnnouncements',
    async () => {
        const response = await getAnnouncements();
        return response?.data;
    }
);

export const announcementsSlice = createSlice({
    name: 'announcements',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get Announcements
            .addCase(GetAnnouncementsAsync.pending, (state) => {
                state.getAnnouncements = AsyncRequestStatus.Pending;
            })
            .addCase(GetAnnouncementsAsync.fulfilled, (state, action) => {
                state.getAnnouncements = AsyncRequestStatus.Fulfilled;

                if (action?.payload?.data?.length) {
                    action.payload.data.forEach((x: Announcement) => {
                        const { eventStartTime, eventEndTime, message } = x;

                        if (eventStartTime && eventEndTime && message) {
                            const correctionMap = new Map<string, string>()
                                .set(AnnouncementsTokenEnum.StartTime, formatDate(eventStartTime))
                                .set(AnnouncementsTokenEnum.EndTime, formatDate(eventEndTime));

                            x.message = replaceAllTokens(message, correctionMap);
                        }
                    });

                    state.announcements = action.payload.data;
                }
            })
            .addCase(GetAnnouncementsAsync.rejected, (state) => {
                state.getAnnouncements = AsyncRequestStatus.Rejected;
            });
    },
});

export const GetAnnouncementsSelector = (state: RootState): Array<Announcement> =>
    state.announcements.announcements;

export default announcementsSlice.reducer;
