import { createSlice } from '@reduxjs/toolkit';

export interface NotificationFilterIDState {
    notificationTypes: number[];
}
const initialStateFilterID: NotificationFilterIDState = {
    notificationTypes: [],
};

export const notificationFilterIDSlice = createSlice({
    name: 'notificationFilter',
    initialState: initialStateFilterID,
    reducers: {
        resetNotificationFilter: () => initialStateFilterID,
        setNotificationFilterIDs(state, action) {
            state.notificationTypes = action.payload;
        },
    },
});

export const { resetNotificationFilter } = notificationFilterIDSlice.actions;
export const { setNotificationFilterIDs } = notificationFilterIDSlice.actions;
export default notificationFilterIDSlice.reducer;
