/* eslint-disable no-underscore-dangle */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import debounce from 'lodash/debounce';
import { NAF_CONNECT_API_BASE_URL } from 'app/shared/helpers/settings.helper';
import { redirectToLandingPage, refreshToken } from './_httpClient/http-client.service';
import { localStorageHelper } from 'app/shared/helpers';
import { NcAxiosRequestConfig } from 'app/store/api/axiosBaseQuery';

type QueuedRequest = () => void;

// Assuming a typical token lifespan (in milliseconds)
const TYPICAL_TOKEN_LIFESPAN = 15 * 60 * 1000; // e.g., 15 minutes
const REFRESH_THRESHOLD = 10 * 60 * 1000; // e.g., refresh 10 minutes before expiration
const USER_INTERACTION_DEBOUNCE_DELAY = 1 * 60 * 1000; // e.g., 1 minute

// Create an Axios instance with a base URL.
export const nafConnectAxiosInstance = axios.create({
    baseURL: NAF_CONNECT_API_BASE_URL,
});

// State to track if the token is currently being refreshed.
let isRefreshingToken = false;

// Queue to hold requests that are made while the token is being refreshed.
let queuedRequests: QueuedRequest[] = [];

// Function to check if the token is nearing expiration.
const isTokenNearingExpiration = (): boolean => {
    const lastRefreshTime = localStorageHelper.get<number>('@nc_token_last_action') || 0;
    const currentTime = Date.now();
    return currentTime - lastRefreshTime > TYPICAL_TOKEN_LIFESPAN - REFRESH_THRESHOLD;
};

// Debounced function for token refresh
const debouncedTokenRefresh = debounce(
    async () => {
        await refreshTokenAndUpdateTime();
    },
    USER_INTERACTION_DEBOUNCE_DELAY,
    { leading: true, trailing: false }
);

// Event listener callback
const userActivityHandler = (): void => {
    debouncedTokenRefresh();
};

// Function to refresh token and update the last refresh time.
const refreshTokenAndUpdateTime = async (): Promise<void> => {
    if (isRefreshingToken || !isTokenNearingExpiration()) {
        return; // Skip if already refreshing or token isn't nearing expiration
    }
    isRefreshingToken = true;
    try {
        const newToken = await refreshToken();
        nafConnectAxiosInstance.defaults.headers.common.Authorization = `Bearer ${newToken}`;
        processQueuedRequests();
    } catch (error) {
        redirectToLandingPage();
    } finally {
        isRefreshingToken = false;
    }
};

// Function to process queued requests once the token has been refreshed.
const processQueuedRequests = (): void => {
    queuedRequests.forEach((callback) => callback());
    queuedRequests = [];
};

// Attach global event listeners
export const attachSessionActivityListeners = (): void => {
    window.addEventListener('click', userActivityHandler);
    window.addEventListener('keypress', userActivityHandler);
};

// Detach global event listeners
export const detachSessionActivityListeners = (): void => {
    window.removeEventListener('click', userActivityHandler);
    window.removeEventListener('keypress', userActivityHandler);
};

// Request interceptor to handle the token refresh logic.
nafConnectAxiosInstance.interceptors.request.use(
    async (config) => {
        if (isRefreshingToken) {
            return new Promise((resolve) => {
                queuedRequests.push(() => resolve(config));
            });
        }
        await refreshTokenAndUpdateTime();

        return config;
    },
    (error: AxiosError) => Promise.reject(error)
);

// Response interceptor to handle errors, specifically 400 and 401 responses.
nafConnectAxiosInstance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
        if (!error.config) {
            return Promise.reject(error);
        }
        const originalRequest = error.config as NcAxiosRequestConfig & { _retry?: boolean };
        const suppressRedirect = originalRequest?.suppressRedirect;
        if (error.response?.status === 400) {
            if (!suppressRedirect) {
                redirectToLandingPage();
            }

            return Promise.reject(error);
        }

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            if (!isRefreshingToken) {
                await refreshTokenAndUpdateTime();
                return nafConnectAxiosInstance(originalRequest);
            }
            // If the token is being refreshed, queue the request
            return new Promise<AxiosRequestConfig>((resolve) => {
                queuedRequests.push(() => {
                    // Set the authorization header with the updated token
                    originalRequest.headers = originalRequest.headers || {};
                    originalRequest.headers.Authorization =
                        nafConnectAxiosInstance.defaults.headers.common.Authorization;
                    resolve(nafConnectAxiosInstance(originalRequest));
                });
            });
        }

        return Promise.reject(error);
    }
);

export default nafConnectAxiosInstance;
