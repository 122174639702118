import { ReactElement, SVGProps } from 'react';

const GreyCircleXIcon = ({ width }: SVGProps<SVGPathElement>): ReactElement => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" width={width}>
        <path
            d="M10 0c5.5 0 10 4.5 10 10s-4.5 10-10 10S0 15.5 0 10 4.5 0 10 0z"
            style={{
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: '#666',
            }}
        />
        <path
            d="m11.6 10 2.3-2.3c.3-.3.3-.8 0-1l-.5-.5c-.3-.3-.8-.3-1 0L10 8.4 7.7 6.1c-.3-.3-.8-.3-1 0l-.6.5c-.3.3-.3.8 0 1L8.4 10l-2.3 2.3c-.3.3-.3.8 0 1l.5.5c.3.3.8.3 1 0l2.3-2.3 2.3 2.3c.3.3.8.3 1 0l.5-.5c.3-.3.3-.8 0-1L11.6 10z"
            style={{
                fill: '#fff',
            }}
        />
    </svg>
);

export default GreyCircleXIcon;
