import { getAuthToken, httpMethod } from 'app/services/_httpClient';
import { axiosBaseQuery } from '../axiosBaseQuery';
import { NAF_CONNECT_API_BASE_URL } from 'app/shared/helpers/settings.helper';
import { createApi } from '@reduxjs/toolkit/query/react';

import { PartnerDevelopmentManager } from 'app/shared/models';
import { toasterError, toasterSuccess } from 'app/shared/helpers';
import {
    PartnerDevelopmentManagerResponse,
    AddPartnerDevelopmentManagerResponse,
    AddPartnerDevelopmentManagerRequest,
    UpdatePartnerDevelopmentManagerResponse,
    UpdatePartnerDevelopmentManagerRequest,
} from 'app/services/partner-development-manager/models';

export const partnerDevelopmentManagerApi = createApi({
    reducerPath: 'partner-development-manager-api',
    baseQuery: axiosBaseQuery({
        baseUrl: `${NAF_CONNECT_API_BASE_URL}/agentRelationshipManagers`,
    }),
    endpoints: (builder) => ({
        getPartnerDevelopmentManagers: builder.query<PartnerDevelopmentManagerResponse, void>({
            query: () => ({
                url: '?includeInactive=true',
                method: httpMethod.Get,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${getAuthToken()}`,
                },
            }),
            transformResponse: (response: { data: PartnerDevelopmentManagerResponse }) =>
                response.data,
        }),
        addPartnerDevelopmentManager: builder.mutation<
            AddPartnerDevelopmentManagerResponse,
            AddPartnerDevelopmentManagerRequest
        >({
            query: (data) => ({
                url: '',
                method: httpMethod.Post,
                headers: {
                    Authorization: `Bearer ${getAuthToken()}`,
                },
                data,
            }),
            transformResponse: (response: { data: AddPartnerDevelopmentManagerResponse }) =>
                response.data,
            onQueryStarted: async (
                { firstName, lastName, email },
                { dispatch, queryFulfilled }
            ) => {
                try {
                    const { data } = await queryFulfilled;
                    const { id, signUpUrl } = data;

                    const newAgent: PartnerDevelopmentManager = {
                        id: id ?? '',
                        firstName,
                        lastName,
                        email,
                        active: true,
                        signUpUrl: signUpUrl ?? '',
                    };

                    const getPartnerDevelopmentManagerPatch = dispatch(
                        partnerDevelopmentManagerApi.util.updateQueryData(
                            'getPartnerDevelopmentManagers',
                            undefined,
                            (draft) => {
                                draft.unshift(newAgent);
                            }
                        )
                    );

                    queryFulfilled.catch(() => {
                        getPartnerDevelopmentManagerPatch.undo();
                    });
                    toasterSuccess('Success');
                } catch {
                    toasterError('Error');
                }
            },
        }),
        updatePartnerDevelopmentManager: builder.mutation<
            UpdatePartnerDevelopmentManagerResponse,
            UpdatePartnerDevelopmentManagerRequest
        >({
            query: (data) => ({
                url: `/${data.id}`,
                method: httpMethod.Put,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${getAuthToken()}`,
                },
                data,
            }),
            transformResponse: (response: { data: UpdatePartnerDevelopmentManagerResponse }) =>
                response.data,
            onQueryStarted: async (
                { id, firstName, lastName, email, active, signUpUrl },
                { dispatch, queryFulfilled }
            ) => {
                const updatedAgent: PartnerDevelopmentManager = {
                    id: id ?? '',
                    firstName,
                    lastName,
                    email,
                    active,
                    signUpUrl: signUpUrl ?? '',
                };

                const getPartnerDevelopmentManagerPatch = dispatch(
                    partnerDevelopmentManagerApi.util.updateQueryData(
                        'getPartnerDevelopmentManagers',
                        undefined,
                        (draft) => {
                            const index = draft.findIndex((a) => a.id === id);

                            if (index >= 0) {
                                draft[index] = updatedAgent;
                            }
                        }
                    )
                );

                queryFulfilled.catch(() => {
                    getPartnerDevelopmentManagerPatch.undo();
                });
            },
        }),
    }),
});

export const {
    useGetPartnerDevelopmentManagersQuery,
    useAddPartnerDevelopmentManagerMutation,
    useUpdatePartnerDevelopmentManagerMutation,
} = partnerDevelopmentManagerApi;
