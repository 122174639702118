import { apiRequest, getRealEstateAgentId, httpMethod } from '../_httpClient';
import { ServiceResponse } from '../_httpClient/models/http-client.model';
import { EmailConsentRequest } from './models/email-consent-request.model';

export const sendConsentEmail = (request: EmailConsentRequest): ServiceResponse<boolean> => {
    const endpointUrl = `/realEstateAgents/${getRealEstateAgentId()}/deals/${
        request.clientId
    }/loans/${request.loanId}/consent`;

    return apiRequest<null, boolean>(endpointUrl, httpMethod.Post, null).then((response) => {
        if ('isAxiosError' in response) return null;

        return response;
    });
};
