import { createContext, useState, useEffect, ReactNode } from 'react';
import { NAF_CONNECT_API_BASE_URL, NAF_CONNECT_API_KEY } from 'app/shared/helpers/settings.helper';

interface EnvProvider {
    children: ReactNode;
}

export interface UIEnvironmentSettings {
    NAF_CONNECT_API_URL: string;
    TEMPLATE_GUID_TERMS_OF_USE: string;
    TEMPLATE_GUID_PRIVACY_POLICY: string;
    TEMPLATE_GUID_ELECTRONIC_CONSENT: string;
    TEMPLATE_GUID_CO_BRANDING_AGREEMENT: string;
    REFRESH_TOKEN_DELAY: number;
    GA_MEASUREMENT_ID: string;
    GA_MEASUREMENT_ID_TEST: string;
    BLOB_STORAGE_URL: string;
    GOOGLE_CLIENT_ID: string;
    MICROSOFT_CLIENT_ID: string;
    LINKEDIN_CLIENT_ID: string;
    REDIRECT_URI: string;
    NC_CANONICAL_URL: string;
    TERMS_URL: string;
    PRIVACY_URL: string;
    FACEBOOK_APP_ID: string;
    FACEBOOK_SUPPORT_PHONE_NUMBER: string;
    FACEBOOK_SUPPORT_EMAIL: string;
    NAF_CONNECT_API_KEY: string;
    VAPID_PUBLIC_KEY: string;
    PING_AUTHORIZATION_ENDPOINT: string;
    PING_CLIENT_ID: string;
}

export const EnvContext = createContext<UIEnvironmentSettings>({} as UIEnvironmentSettings);

export const EnvProvider = ({ children }: EnvProvider): JSX.Element => {
    const [env, setEnv] = useState<UIEnvironmentSettings>({} as UIEnvironmentSettings);

    useEffect(() => {
        const url = `${NAF_CONNECT_API_BASE_URL}/settings/ui`;
        fetch(url, {
            headers: {
                'X-API-Key': NAF_CONNECT_API_KEY,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const envVariables = data.data;
                setEnv(envVariables);
            })
            .catch(() => {});
    }, []);

    return <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
};
