import { httpMethod } from 'app/services/_httpClient';
import {
    SmsUnsubscribeRequest,
    SmsUnsubscribeResponse,
    ValidateSmsTokenRequest,
    ValidateSmsTokenResponse,
} from 'app/store/api/text-activity/models/text-activity.model';

import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../axiosBaseQuery';
import { NAF_CONNECT_API_BASE_URL } from 'app/shared/helpers/settings.helper';

export const textMessageActivityApi = createApi({
    reducerPath: 'textMessageActivity-api',
    baseQuery: axiosBaseQuery({
        baseUrl: `${NAF_CONNECT_API_BASE_URL}/textMessageActivity/`,
    }),
    endpoints: (builder) => ({
        validateSmsToken: builder.mutation<ValidateSmsTokenResponse, ValidateSmsTokenRequest>({
            query: (data) => ({
                url: 'validateToken',
                method: httpMethod.Post,
                data,
            }),
            transformResponse: (response: { data: ValidateSmsTokenResponse }) => response.data,
        }),
        smsUnsubscribe: builder.mutation<SmsUnsubscribeResponse, SmsUnsubscribeRequest>({
            query: (data) => ({
                url: 'unsubscribe',
                method: httpMethod.Post,
                data,
            }),
            transformResponse: (response: { data: SmsUnsubscribeResponse }) => response.data,
        }),
    }),
});

export const { useValidateSmsTokenMutation, useSmsUnsubscribeMutation } = textMessageActivityApi;
