import dayjs from 'dayjs';
import { Address } from '../models';

export const getInitials = (fullName: string): string => {
    let initials = '';

    if (fullName !== undefined && fullName.split(' ').length >= 2) {
        const nameArr: string[] = fullName.split(' ');
        initials = `${nameArr[0].charAt(0)} ${nameArr[1].charAt(0)}`.toUpperCase();
    }

    return initials;
};

export const toProperCase = (name?: string): string => {
    if (!name) return '';

    const firstLetter = name.charAt(0).toUpperCase();
    const remaining = name.slice(1);

    return `${firstLetter}${remaining}`;
};

export const constructAddress = (address?: Address): string => {
    let displayAddress = '';

    if (address) {
        displayAddress = `${address?.address1}
		${address?.address2 ? ` ${address?.address2}` : ''}, 
		${address?.city}, ${address?.state} ${address?.zipCode}`;
    }

    return displayAddress;
};

export const formatDateDay = (date: Date, format = 'dddd, MMMM DD, YYYY'): string =>
    date ? dayjs(date).format(format) : 'N/A';

export const formatDate = (date?: Date, format = 'MM/DD/YYYY'): string =>
    date ? dayjs(date).format(format) : 'N/A';

export const formatTime = (date: Date, format = 'hh:mm A'): string =>
    date ? dayjs(date).format(format) : 'N/A';

export const formatNotesDate = (date: Date, format = 'MMM DD, YYYY'): string =>
    date ? dayjs(date).format(format) : 'N/A';

export const currencyFormatter = (value: number): string => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
    });

    return formatter.format(Math.round(value));
};

export const phoneNumberFormat = (value: string, ex?: string): string => {
    if (!value) return '';

    if (value.indexOf('-') > -1) return value; // Return as-is if already formatted

    // Slice and format the phone number
    let phoneNumber = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;

    // Append extension if provided
    if (ex !== '' && ex !== undefined) {
        phoneNumber += ` x${ex}`;
    }

    return phoneNumber;
};

export const queryParametersToString = (params: { [x: string]: string }): string =>
    Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');

export const replaceAllTokens = (text: string, correction: Map<string, string>): string => {
    const reg = new RegExp(Array.from(correction.keys()).join('|'), 'g');
    return text.replace(reg, (matched: string) => correction.get(matched) || '');
};

export const stripHtmlTags = (htmlString: string): string =>
    htmlString
        .replace(/<[^>]*>/g, ' ')
        .replace(/\s+/g, ' ')
        .replace(/&nbsp;/g, ' ')
        .trim();

export const truncateStringAddDots = (str: string | undefined, maxLength: number): string => {
    if (str !== undefined) {
        const truncatedString = `${str.substring(0, maxLength - 3)}...`;
        const newString = str.length > maxLength ? truncatedString : str;

        return newString;
    }

    return '';
};

export const addToClipboard = (content?: string): void => {
    if (content) {
        navigator.clipboard.writeText(content);
    }
};
