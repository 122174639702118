import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export const ConvertToLocalTimezone = (date: Date): Dayjs => {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const pstDate = dayjs.tz(date, 'America/Los_Angeles');
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userDate = pstDate.tz(userTimezone);
    return userDate;
};
