import { createContext, useState, useEffect, ReactNode, useMemo } from 'react';
import { Role } from 'app/shared/models';
import usePingAuth from 'app/shared/hooks/use-ping-auth.hook';
import { useSigninMutation } from 'app/store/api/admin/admin.api';
import { parseJwt } from 'app/services';
import { JWT } from 'app/services/_httpClient/models/http-client.model';
import { AdminInfo } from 'app/store/api/admin/admin.models';

interface AdminSignInProvider {
    children: ReactNode;
}

export interface AdminSignInContext {
    role?: Role;
    id?: string;
    info?: AdminInfo;
}

export const AdminSignInContext = createContext<AdminSignInContext>({
    role: undefined,
});

export const AdminSignInProvider = ({ children }: AdminSignInProvider): JSX.Element => {
    const [parsedJwt, setParsedJwt] = useState<JWT>();
    const { pingToken } = usePingAuth();
    const [signin, { data: signinToken }] = useSigninMutation();

    useEffect(() => {
        if (pingToken) {
            const request = {
                token: pingToken,
            };
            signin(request);
        }
    }, [pingToken]);

    useEffect(() => {
        if (signinToken) {
            const parsedJwt = parseJwt(signinToken.authenticationToken.token);
            setParsedJwt(parsedJwt);
        }
    }, [signinToken]);

    const value = useMemo(() => {
        const { nc_role: role, nc_aid: id } = parsedJwt || {};
        return {
            role: role ? +role : role,
            id,
            info: signinToken?.adminInfo,
        };
    }, [parsedJwt]);

    return <AdminSignInContext.Provider value={value}>{children}</AdminSignInContext.Provider>;
};
