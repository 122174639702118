import { base64ToUnit8Array } from './base64.helper';

export const downloadBase64 = (base64: string): boolean => {
    try {
        const unit8Array = base64ToUnit8Array(base64);
        const blob = new Blob([unit8Array], { type: 'data:application/pdf' });
        const fileURL = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = fileURL;
        link.download = 'pal.pdf';
        link.click();

        return true;
    } catch (error) {
        return false;
    }
};
