import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseApiQuery } from '../axiosBaseQuery';
import { NAF_CONNECT_API_BASE_URL } from 'app/shared/helpers/settings.helper';
import { PalEmailRequest } from './models';
import { getRealEstateAgentId } from 'app/services';
import { httpMethod } from 'app/services/_httpClient';

export const communicationApi = createApi({
    reducerPath: 'communication-api',
    baseQuery: axiosBaseApiQuery({ baseUrl: NAF_CONNECT_API_BASE_URL }),
    endpoints: (builder) => ({
        sendPalEmail: builder.mutation<boolean, PalEmailRequest>({
            query: (data) => ({
                url: `/realEstateAgents/${getRealEstateAgentId()}/communications/palAttachmentEmails`,
                method: httpMethod.Post,
                data,
            }),
        }),
    }),
});

export const { useSendPalEmailMutation } = communicationApi;
