import { apiRequest, getRealEstateAgentId, httpMethod } from '../_httpClient';
import { ServiceResponse } from '../_httpClient/models/http-client.model';
import { NotificationResponse } from './models';

export const dismissAllNotification = (): ServiceResponse<boolean> => {
    const endpointUrl = `/realEstateAgents/${getRealEstateAgentId()}/notifications`;

    return apiRequest<null, boolean>(endpointUrl, httpMethod.Delete, null).then((response) => {
        if ('isAxiosError' in response) return null;

        return response;
    });
};

export const dismissOneNotification = (id: string): ServiceResponse<boolean> => {
    const endpointUrl = `/realEstateAgents/${getRealEstateAgentId()}/notifications/${id}`;

    return apiRequest<null, boolean>(endpointUrl, httpMethod.Delete, null).then((response) => {
        if ('isAxiosError' in response) return null;

        return response;
    });
};

export const getAllNotification = (): ServiceResponse<NotificationResponse> => {
    const endpointUrl = `/realEstateAgents/${getRealEstateAgentId()}/notifications`;

    return apiRequest<null, NotificationResponse>(endpointUrl, httpMethod.Get, null).then(
        (response) => {
            if ('isAxiosError' in response) return null;

            return response;
        }
    );
};
