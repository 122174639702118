import { toast, ToastOptions } from 'react-toastify';

import styles from './toaster.module.scss';
import { ReactNode } from 'react';

const successToasterStyle: ToastOptions = {
    position: 'top-right',
    autoClose: 4000,
    closeOnClick: true,
    pauseOnHover: false,
    className: styles['success-toaster'],
};

const errorToasterStyle: ToastOptions = {
    position: 'top-right',
    autoClose: 4000,
    closeOnClick: true,
    pauseOnHover: false,
    className: styles['failure-toaster'],
};

export const toasterSuccess = (content: ReactNode): void => {
    toast.success(content, successToasterStyle);
};

export const toasterError = (content: ReactNode): void => {
    toast.error(content, errorToasterStyle);
};
