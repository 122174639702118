export enum Color {
    nafLight = '#DDE2EC',
    nafDark = '#1D1F2A',
    nafPrimaryBlue = '#091644',
    primaryButtonHoverBlue = '#3464C7',
    linkBlue = '#0000ee',
    transparent = 'transparent',
    white = '#FFFFFF',
    black = '#000000',
    darkCharcoal = '#333333',
    lightGrey = '#767676',
    lightGrey2 = '#D9D9D9',
    lightGrey3 = '#CCCCCC',
    catskillWhite = '#F0F3F8',
    aquaHaze = '#F6F8FA',
    dustGray = '#999999',
    colorSilverChalice = '#B1B1B1',
    doveGray = '#707070',
    colorMercury = '#E2E2E2',
    lightBlue = '#2A5C9E',
    lighterBlue = '#F9FEFF',
    blue = '#3E5D85',
    blumine = '#1E4D73',
    astronautBlue = '#013560',
    cornflowerBlue = '#615F9F1A',
    aliceBlue = '#F2F9FF',
    stratos = '#00000040',
    shakespeare = '#3F93CF',
    sky = '#4689C8',
    zumthor = '#EBF5FF',
    perano = '#AAD1F1',
    laPalma = '#179917',
    zigzyGreen = '#0CAF0C',
    alert = '#C70731',
    border1 = '#C4DBED',
    border2 = '#DFEBF5',
    gray40 = '#666666',
    warningYellow = '#EDAC1B',
    marinerBlue = '#3B6CCF',
    alto = '#DDDDDD',
    goldStar = '#FABF6F',
}
