import { Action, UIActionEnum } from 'app/store/api/integrations/models/integrations.model';
import { useAppDispatch } from 'app/store/hooks';
import { setLoginDetails, setRegistrationDetails } from 'app/store/slices/landing-page.slice';
import { setLeadDetails } from 'app/store/slices/refer-client.slice';
import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

interface UIActionsProviderProps {
    children: ReactNode;
}

interface UIActionsContext {
    currentAction: Action;
    loadActions: (actions: Action[]) => void;
    dequeueAction: () => void;
    setReaSource: (source: string) => void;
    redirectUrl: string;
    setRedirectUrl: (url: string) => void;
}

const UIActionsContext = createContext<UIActionsContext>({
    currentAction: {} as Action,
    loadActions: () => {},
    dequeueAction: () => {},
    setReaSource: () => {},
    redirectUrl: '',
    setRedirectUrl: () => {},
});

export const UIActionsProvider = ({ children }: UIActionsProviderProps): JSX.Element => {
    const [actions, setActions] = useLocalStorage<Action[]>('@nc_actions', []);
    const [redirectUrl, setRedirectUrl] = useLocalStorage('@nc_redirect_url', '');
    const location = useLocation();
    const previousLocation = useRef('');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [source, setSource] = useState('');

    const setReaSource = useCallback(
        (source: string): void => {
            setSource(source);
        },
        [setSource]
    );

    useEffect(() => {
        const currentAction = actions[0];
        if (currentAction) {
            switch (currentAction.action) {
                case UIActionEnum.Signup: {
                    dispatch(
                        setRegistrationDetails({
                            agentFirstName: currentAction.agentFirstName,
                            agentLastName: currentAction.agentLastName,
                            agentEmail: currentAction.agentEmailAddress,
                            agentStateCode: currentAction.agentStateCode,
                            agentPhone: currentAction.agentPhoneNumber,
                            nafHomesId: currentAction.nafHomesId,
                            reaSource: source,
                        })
                    );
                    break;
                }
                case UIActionEnum.Login: {
                    dispatch(
                        setLoginDetails({
                            agentEmailAddress: currentAction.agentEmailAddress,
                        })
                    );
                    break;
                }
                case UIActionEnum.ClientReferral: {
                    const {
                        leadFirstName,
                        leadLastName,
                        leadEmailAddress,
                        leadAddress1,
                        leadAddress2,
                        leadZipCode,
                        leadStateCode,
                        leadPhoneNumber,
                    } = currentAction;

                    dispatch(
                        setLeadDetails({
                            leadFirstName,
                            leadLastName,
                            leadEmailAddress,
                            leadAddress1,
                            leadAddress2,
                            leadZipCode,
                            leadStateCode,
                            leadPhoneNumber,
                        })
                    );
                    break;
                }
                case UIActionEnum.ClientStatus: {
                    break;
                }
                default:
                    break;
            }
        }
        if (currentAction && currentAction.actionUrl) {
            const url = new URL(currentAction.actionUrl);
            const pathSegments = url.pathname.split('/').filter((segment) => segment.length > 0);
            const relevantPath = pathSegments.join('/');

            previousLocation.current = location.pathname;

            // Condition prevents actions navigating when returning from LinkedIn SSO login
            if (location.pathname !== '/') {
                navigate(relevantPath);
            }
        }
    }, [actions]);

    useEffect(() => {
        if (actions.length) {
            // pattern to detect deals url
            const pattern =
                /^\/rea\/deals\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\/loans\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

            if (
                (previousLocation.current === '/rea/clients' &&
                    location.pathname === '/rea/refer-client') ||
                (previousLocation.current === '/rea/clients' &&
                    location.pathname === '/rea/communications') ||
                (previousLocation.current === '/rea/clients' && pattern.test(location.pathname))
            ) {
                dequeueAction();
            }
        }
    }, [location]);

    const loadActions = useCallback(
        (actions: Action[]): void => {
            setActions(actions);
        },
        [setActions]
    );

    const dequeueAction = useCallback((): void => {
        if (actions.length) {
            setActions((prevActions) => {
                const newActions = prevActions.slice(1);
                return newActions;
            });
        }
    }, [actions, setActions]);

    const value = useMemo(
        () => ({
            currentAction: actions[0],
            loadActions,
            dequeueAction,
            setReaSource,
            redirectUrl,
            setRedirectUrl,
        }),
        [actions, loadActions, dequeueAction, setReaSource, redirectUrl, setRedirectUrl]
    );

    return <UIActionsContext.Provider value={value}>{children}</UIActionsContext.Provider>;
};

export const useActions = (): UIActionsContext => useContext(UIActionsContext);
