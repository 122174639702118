import { AsyncRequestStatus } from 'app/shared/enums';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getFeatureFlag } from 'app/services/feature-flag/feature-flag.service';
import { RootState } from '../store';
import { FeatureFlagRequest } from 'app/services/feature-flag/models';

export interface featureFlagState {
    displayMaintenance: boolean;
    showGoogleButton: boolean;
    showLinkedinButton: boolean;
    showMicrosoftButton: boolean;
    showFacebookButton: boolean;
    showResourcesPage: boolean;
    showCoBorrower: boolean;
    showAdminTemplates: boolean;
    statusDisplayMaintenancePage: AsyncRequestStatus;
    statusGoogleButton: AsyncRequestStatus;
    statusLinkedinButton: AsyncRequestStatus;
    statusMicrosoftButton: AsyncRequestStatus;
    statusFacebookButton: AsyncRequestStatus;
    statusShowResourcesPage: AsyncRequestStatus;
    statusShowCoBorrower: AsyncRequestStatus;
    statusShowAdminTemplates: AsyncRequestStatus;
}

const initialState: featureFlagState = {
    displayMaintenance: false,
    showGoogleButton: false,
    showLinkedinButton: false,
    showMicrosoftButton: false,
    showFacebookButton: false,
    showResourcesPage: false,
    showCoBorrower: false,
    showAdminTemplates: false,
    statusDisplayMaintenancePage: AsyncRequestStatus.Idle,
    statusGoogleButton: AsyncRequestStatus.Idle,
    statusLinkedinButton: AsyncRequestStatus.Idle,
    statusMicrosoftButton: AsyncRequestStatus.Idle,
    statusFacebookButton: AsyncRequestStatus.Idle,
    statusShowResourcesPage: AsyncRequestStatus.Idle,
    statusShowCoBorrower: AsyncRequestStatus.Idle,
    statusShowAdminTemplates: AsyncRequestStatus.Idle,
};

export const DisplayMaintenancePageAsync = createAsyncThunk(
    'featureFlag/DisplayMaintenance',
    async (request: FeatureFlagRequest) => {
        const response = await getFeatureFlag(request);
        return response;
    }
);
export const ShowGoogleButtonAsync = createAsyncThunk(
    'featureFlag/ShowGoogleButton',
    async (request: FeatureFlagRequest) => {
        const response = await getFeatureFlag(request);
        return response;
    }
);

export const ShowLinkedinButtonAsync = createAsyncThunk(
    'featureFlag/ShowLinkedinButton',
    async (request: FeatureFlagRequest) => {
        const response = await getFeatureFlag(request);
        return response;
    }
);

export const ShowMicrosoftButtonAsync = createAsyncThunk(
    'featureFlag/ShowMicrosoftButton',
    async (request: FeatureFlagRequest) => {
        const response = await getFeatureFlag(request);
        return response;
    }
);

export const ShowResourcesPageAsync = createAsyncThunk(
    'featureFlag/ShowResourcesPageAsync',
    async (request: FeatureFlagRequest) => {
        const response = await getFeatureFlag(request);
        return response;
    }
);

export const ShowFacebookButtonAsync = createAsyncThunk(
    'featureFlag/ShowFacebookButton',
    async (request: FeatureFlagRequest) => {
        const response = await getFeatureFlag(request);
        return response;
    }
);

export const ShowCoBorrowerAsync = createAsyncThunk(
    'featureFlag/ShowCoBorrower',
    async (request: FeatureFlagRequest) => {
        const response = await getFeatureFlag(request);
        return response;
    }
);

export const ShowAdminTemplates = createAsyncThunk(
    'featureFlag/ShowAdminTemplates',
    async (request: FeatureFlagRequest) => {
        const response = await getFeatureFlag(request);
        return response;
    }
);

export const featureFlagSlice = createSlice({
    name: 'featureFlag',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Maintenance Page
            .addCase(DisplayMaintenancePageAsync.pending, (state) => {
                state.statusDisplayMaintenancePage = AsyncRequestStatus.Pending;
            })
            .addCase(DisplayMaintenancePageAsync.fulfilled, (state, action) => {
                state.statusDisplayMaintenancePage = AsyncRequestStatus.Fulfilled;
                if (action.payload.data?.data?.enabled) {
                    state.displayMaintenance = true;
                }
            })
            .addCase(DisplayMaintenancePageAsync.rejected, (state) => {
                state.statusDisplayMaintenancePage = AsyncRequestStatus.Rejected;
            })
            // Google Button
            .addCase(ShowGoogleButtonAsync.pending, (state) => {
                state.statusGoogleButton = AsyncRequestStatus.Pending;
            })
            .addCase(ShowGoogleButtonAsync.fulfilled, (state, action) => {
                state.statusGoogleButton = AsyncRequestStatus.Fulfilled;

                if (action.payload.data?.data?.enabled) {
                    state.showGoogleButton = true;
                }
            })
            .addCase(ShowGoogleButtonAsync.rejected, (state) => {
                state.statusGoogleButton = AsyncRequestStatus.Rejected;
            })
            // Linkedin Button
            .addCase(ShowLinkedinButtonAsync.pending, (state) => {
                state.statusLinkedinButton = AsyncRequestStatus.Pending;
            })
            .addCase(ShowLinkedinButtonAsync.fulfilled, (state, action) => {
                state.statusLinkedinButton = AsyncRequestStatus.Fulfilled;
                if (action.payload.data?.data?.enabled) {
                    state.showLinkedinButton = true;
                }
            })
            .addCase(ShowLinkedinButtonAsync.rejected, (state) => {
                state.statusLinkedinButton = AsyncRequestStatus.Rejected;
            })
            // Microsoft Button
            .addCase(ShowMicrosoftButtonAsync.pending, (state) => {
                state.statusMicrosoftButton = AsyncRequestStatus.Pending;
            })
            .addCase(ShowMicrosoftButtonAsync.fulfilled, (state, action) => {
                state.statusMicrosoftButton = AsyncRequestStatus.Fulfilled;
                if (action.payload.data?.data?.enabled) {
                    state.showMicrosoftButton = true;
                }
            })
            .addCase(ShowMicrosoftButtonAsync.rejected, (state) => {
                state.statusMicrosoftButton = AsyncRequestStatus.Rejected;
            })
            // Facebook Button
            .addCase(ShowFacebookButtonAsync.pending, (state) => {
                state.statusFacebookButton = AsyncRequestStatus.Pending;
            })
            .addCase(ShowFacebookButtonAsync.fulfilled, (state, action) => {
                state.statusFacebookButton = AsyncRequestStatus.Fulfilled;
                if (action.payload.data?.data?.enabled) {
                    state.showFacebookButton = true;
                }
            })
            .addCase(ShowFacebookButtonAsync.rejected, (state) => {
                state.statusFacebookButton = AsyncRequestStatus.Rejected;
            })
            // Show Resources Page
            .addCase(ShowResourcesPageAsync.pending, (state) => {
                state.statusShowResourcesPage = AsyncRequestStatus.Pending;
            })
            .addCase(ShowResourcesPageAsync.fulfilled, (state, action) => {
                state.statusShowResourcesPage = AsyncRequestStatus.Fulfilled;
                if (action.payload.data?.data?.enabled) {
                    state.showResourcesPage = true;
                }
            })
            .addCase(ShowResourcesPageAsync.rejected, (state) => {
                state.statusShowResourcesPage = AsyncRequestStatus.Rejected;
            })
            // Show CoBorrower
            .addCase(ShowCoBorrowerAsync.pending, (state) => {
                state.statusShowCoBorrower = AsyncRequestStatus.Pending;
            })
            .addCase(ShowCoBorrowerAsync.fulfilled, (state, action) => {
                state.statusShowCoBorrower = AsyncRequestStatus.Fulfilled;
                if (action.payload.data?.data?.enabled) {
                    state.showCoBorrower = true;
                }
            })
            .addCase(ShowCoBorrowerAsync.rejected, (state) => {
                state.statusShowCoBorrower = AsyncRequestStatus.Rejected;
            })
            // Show Admin Templates
            .addCase(ShowAdminTemplates.pending, (state) => {
                state.statusShowAdminTemplates = AsyncRequestStatus.Pending;
            })
            .addCase(ShowAdminTemplates.fulfilled, (state, action) => {
                state.statusShowAdminTemplates = AsyncRequestStatus.Fulfilled;
                if (action.payload.data?.data?.enabled) {
                    state.showAdminTemplates = true;
                }
            })
            .addCase(ShowAdminTemplates.rejected, (state) => {
                state.statusShowAdminTemplates = AsyncRequestStatus.Rejected;
            });
    },
});

export const FeatureFlagSelector = (state: RootState): featureFlagState => state.featureFlag;

export default featureFlagSlice.reducer;
