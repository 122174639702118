import { v4 as uuidv4 } from 'uuid';
import { ClientFilterOption } from '../models';

export enum ClientFilterOptionsType {
    FEATURED = 'Featured',
    DATE_REFERRED = 'Date Referred',
    CLIENT_NAME = 'Client Name',
    CLOSING_DATE = 'Closing Date',
    LOAN_MILESTONE = 'Loan Milestone',
    OUTSTANDING_CONDITIONS = 'Outstanding Conditions',
}

export const clientFilterOptions = Array.from(Object.keys(ClientFilterOptionsType)).map(
    (x: string): ClientFilterOption => ({
        id: uuidv4(),
        name: ClientFilterOptionsType[x as keyof typeof ClientFilterOptionsType],
    })
);
