import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface LayoutState {
    sidebar: SidebarState;
    isReferralModalOpen: boolean;
    isDismissAllNotificationModal: boolean;
    isFilterNotificationModal: boolean;
}

interface SidebarState {
    isSidebarOpen: boolean;
    isSettingsOpen: boolean;
}

const initialState: LayoutState = {
    sidebar: {
        isSidebarOpen: false,
        isSettingsOpen: false,
    },
    isReferralModalOpen: false,
    isDismissAllNotificationModal: false,
    isFilterNotificationModal: false,
};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        toggleReferralModal(state) {
            state.isReferralModalOpen = !state.isReferralModalOpen;
        },
        toggleDismissAllNotificationModal(state) {
            state.isDismissAllNotificationModal = !state.isDismissAllNotificationModal;
        },
        toggleFilterNotificationModal(state) {
            state.isFilterNotificationModal = !state.isFilterNotificationModal;
        },
        toggleSidebar(state) {
            state.sidebar.isSidebarOpen = !state.sidebar.isSidebarOpen;
        },
        toggleSettings(state) {
            state.sidebar.isSettingsOpen = !state.sidebar.isSettingsOpen;
        },
    },
});

export const sidebarSelector = (state: RootState): SidebarState => state.layout.sidebar;
export const isReferralModalOpenSelector = (state: RootState): boolean =>
    state.layout.isReferralModalOpen;
export const isDismissAllNotificationModalOpenSelector = (state: RootState): boolean =>
    state.layout.isDismissAllNotificationModal;
export const isFilterNotificationPopover = (state: RootState): boolean =>
    state.layout.isFilterNotificationModal;

export const {
    toggleReferralModal,
    toggleDismissAllNotificationModal,
    toggleFilterNotificationModal,
    toggleSidebar,
    toggleSettings,
} = layoutSlice.actions;

export default layoutSlice.reducer;
