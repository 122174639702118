interface StorageHelper {
    set: (key: string, value: unknown) => void;
    get: <T>(key: string) => T | null;
    remove: (key: string) => void;
}

export const localStorageHelper: StorageHelper = {
    set: (key: string, value: unknown): void => {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (e) {
            console.error('Error saving to localStorage', e); // eslint-disable-line no-console
        }
    },
    get: <T>(key: string): T | null => {
        try {
            const data = localStorage.getItem(key);
            return data ? (JSON.parse(data) as T) : null;
        } catch (e) {
            console.error('Error getting data from localStorage', e); // eslint-disable-line no-console
            return null;
        }
    },
    remove: (key: string): void => {
        try {
            localStorage.removeItem(key);
        } catch (e) {
            console.error('Error removing from localStorage', e); // eslint-disable-line no-console
        }
    },
};

export const sessionStorageHelper: StorageHelper = {
    set: (key: string, value: unknown): void => {
        try {
            sessionStorage.setItem(key, JSON.stringify(value));
        } catch (e) {
            console.error('Error saving to sessionStorage', e); // eslint-disable-line no-console
        }
    },
    get: <T>(key: string): T | null => {
        try {
            const data = sessionStorage.getItem(key);
            return data ? (JSON.parse(data) as T) : null;
        } catch (e) {
            console.error('Error getting data from sessionStorage', e); // eslint-disable-line no-console
            return null;
        }
    },
    remove: (key: string): void => {
        try {
            sessionStorage.removeItem(key);
        } catch (e) {
            console.error('Error removing from sessionStorage', e); // eslint-disable-line no-console
        }
    },
};
