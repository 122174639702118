import { NAF_CONNECT_API_BASE_URL } from 'app/shared/helpers/settings.helper';
import { httpMethod, templateManagerRequest } from '../_httpClient';

export const getTemplate = async (templateGuid: string): Promise<string | null> => {
    const endpoint = `${NAF_CONNECT_API_BASE_URL}/Templates/${templateGuid}`;

    const header = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-API-Key': '2789A38C-964A-476E-9ADA-E01235740CAF',
    };

    // Template manager endpoint
    const template = await templateManagerRequest(endpoint, httpMethod.Get, header, null);

    if (template.data.content != null) {
        return template.data.content;
    }
    return null;
};
