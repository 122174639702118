export enum Milestone {
    Canceled = -1, // not actual milestone, used for filtering purposes
    Undefined = 0,
    ReferralSubmitted = 1,
    PreApproved = 2,
    SubmittedToUw = 3,
    ConditionallyApproved = 4,
    FinalReview = 5,
    ClearedToClose = 6,
    Funded = 7,
}
