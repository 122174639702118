import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseApiQuery, axiosBaseQuery } from '../axiosBaseQuery';
import { NAF_CONNECT_API_BASE_URL, NAF_CONNECT_API_KEY } from 'app/shared/helpers/settings.helper';
import { httpMethod } from 'app/services/_httpClient';
import {
    GetTemplateRequest,
    GetTemplateResponse,
    CreateTemplateContentRequest,
    UpdateTemplateContentRequest,
} from './models/templates.model';

export const templatesApiWithApiKey = createApi({
    reducerPath: 'templates-api-with-api-key',
    baseQuery: axiosBaseQuery({ baseUrl: `${NAF_CONNECT_API_BASE_URL}/templates/` }),
    tagTypes: ['Template'],
    endpoints: (builder) => ({
        getTemplate: builder.query<GetTemplateResponse, GetTemplateRequest>({
            query: ({ guid, activityType, type }) => {
                const queryParts = [];

                if (activityType !== undefined)
                    queryParts.push(`activityType=${encodeURIComponent(activityType)}`);
                if (type !== undefined) queryParts.push(`type=${encodeURIComponent(type)}`);

                const url = `${guid}?${queryParts.join('&')}`;

                return {
                    url,
                    method: httpMethod.Get,
                    headers: {
                        'X-API-Key': NAF_CONNECT_API_KEY,
                    },
                };
            },
            transformResponse: (response: { data: GetTemplateResponse }) => response.data,
            providesTags: (result, error, { guid }) => [{ type: 'Template', id: guid }],
        }),
    }),
});

export const templatesApiWithBearerToken = createApi({
    reducerPath: 'templates-api-with-bearer-token',
    baseQuery: axiosBaseApiQuery({ baseUrl: `${NAF_CONNECT_API_BASE_URL}/templates` }),
    tagTypes: ['Template'],
    endpoints: (builder) => ({
        getTemplate: builder.query<GetTemplateResponse, GetTemplateRequest>({
            query: ({ activityType, type, useDefault }) => {
                const queryParts = [];

                if (activityType !== undefined)
                    queryParts.push(`activityType=${encodeURIComponent(activityType)}`);
                if (type !== undefined) queryParts.push(`type=${encodeURIComponent(type)}`);
                if (useDefault) queryParts.push(`useDefault=true`);

                const url = `?${queryParts.join('&')}`;

                return {
                    url,
                    method: httpMethod.Get,
                };
            },
            transformResponse: (response: { data: GetTemplateResponse }) => response.data,
        }),
        createTemplateContent: builder.mutation<GetTemplateResponse, CreateTemplateContentRequest>({
            query: (data) => ({
                url: '',
                method: httpMethod.Post,
                data,
            }),
            transformResponse: (response: { data: GetTemplateResponse }) => response.data,
        }),
        updateTemplateContent: builder.mutation<GetTemplateResponse, UpdateTemplateContentRequest>({
            query: (data) => ({
                url: `/${data.templateGuid}`,
                method: httpMethod.Put,
                data,
            }),
            transformResponse: (response: { data: GetTemplateResponse }) => response.data,
        }),
    }),
});

export const { useGetTemplateQuery } = templatesApiWithApiKey;

export const {
    useGetTemplateQuery: useGetTemplateWithBearerToken,
    useLazyGetTemplateQuery: useLazyGetTemplateWithBearerToken,
    useCreateTemplateContentMutation,
    useUpdateTemplateContentMutation,
} = templatesApiWithBearerToken;
