import { Color } from 'app/shared/enums';
import { ReactElement, SVGProps } from 'react';

const CirclesLockIcon = ({ width }: SVGProps<SVGPathElement>): ReactElement => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" xmlSpace="preserve" width={width}>
        <style>{'.st0{fill:#666}.st1{fill:#fff}'}</style>
        <g transform="translate(-576 -1976)">
            <circle
                id="Ellipse_287"
                className="st0"
                cx={612}
                cy={2012}
                r={36}
                style={{
                    fillRule: 'evenodd',
                    clipRule: 'evenodd',
                    fill: Color.marinerBlue,
                }}
            />
            <g id="Path_3274">
                <path
                    className="st0"
                    d="M29 57c-3.8 0-7.4-.7-10.9-2.2-3.3-1.4-6.3-3.4-8.9-6s-4.6-5.6-6-8.9C1.7 36.4 1 32.8 1 29s.7-7.4 2.2-10.9c1.4-3.3 3.4-6.3 6-8.9s5.6-4.6 8.9-6C21.6 1.7 25.2 1 29 1s7.4.7 10.9 2.2c3.3 1.4 6.3 3.4 8.9 6s4.6 5.6 6 8.9C56.3 21.6 57 25.2 57 29s-.7 7.4-2.2 10.9c-1.4 3.3-3.4 6.3-6 8.9s-5.6 4.6-8.9 6C36.4 56.3 32.8 57 29 57z"
                    transform="translate(583 1983)"
                    style={{
                        fillRule: 'evenodd',
                        clipRule: 'evenodd',
                        fill: Color.marinerBlue,
                    }}
                />
                <path
                    className="st1"
                    d="M29 2c-3.6 0-7.2.7-10.5 2.1-3.2 1.4-6.1 3.3-8.6 5.8s-4.4 5.4-5.8 8.6C2.7 21.8 2 25.4 2 29s.7 7.2 2.1 10.5c1.4 3.2 3.3 6.1 5.8 8.6s5.4 4.4 8.6 5.8C21.8 55.3 25.4 56 29 56s7.2-.7 10.5-2.1c3.2-1.4 6.1-3.3 8.6-5.8s4.4-5.4 5.8-8.6C55.3 36.2 56 32.6 56 29s-.7-7.2-2.1-10.5c-1.4-3.2-3.3-6.1-5.8-8.6s-5.4-4.4-8.6-5.8C36.2 2.7 32.6 2 29 2m0-2c16 0 29 13 29 29S45 58 29 58 0 45 0 29 13 0 29 0z"
                    transform="translate(583 1983)"
                />
            </g>
            <path
                className="st1"
                d="M623.8 2009.6c.9 0 1.6.3 2.3.9.6.6.9 1.4.9 2.3v12.8c0 .9-.3 1.6-.9 2.3-.6.6-1.4.9-2.3.9h-23.4c-.9 0-1.6-.3-2.3-.9-.6-.6-.9-1.4-.9-2.3v-12.8c0-.9.3-1.6.9-2.3.6-.6 1.4-.9 2.3-.9h1.6v-4.8c0-2.8 1-5.2 3-7.1 2-2 4.3-3 7.1-3s5.2 1 7.1 3c2 2 3 4.3 3 7.1v4.8h1.6zm-6.9 0v-4.8c0-1.3-.5-2.5-1.4-3.4s-2.1-1.4-3.4-1.4c-1.3 0-2.5.5-3.4 1.4s-1.4 2.1-1.4 3.4v4.8h9.6z"
            />
        </g>
    </svg>
);

export default CirclesLockIcon;
