import axios, { AxiosError, AxiosPromise, AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import { jwtDecode } from 'jwt-decode';

import { NAF_CONNECT_API_BASE_URL } from 'app/shared/helpers/settings.helper';
import { localStorageHelper } from 'app/shared/helpers';
import { httpMethod } from './http-method.enum';
import {
    ApiBaseResponse,
    ApiResponse,
    JWT,
    RefreshTokenResponse,
    VersionResponse,
} from './models/http-client.model';
import { nafConnectAxiosInstance } from '../axios-instance';
import { setAuthToken } from '../auth/auth.service';

export const redirectToLandingPage = (): void => {
    window.location.href = `${window.location.origin}/`;
};

export const httpRequest = <D, R>(
    endpointUrl: string,
    method: httpMethod,
    headers?: RawAxiosRequestHeaders,
    data?: D
): Promise<ApiResponse<R>> =>
    axios({
        baseURL: NAF_CONNECT_API_BASE_URL,
        url: endpointUrl,
        method,
        headers,
        data,
        withCredentials: true,
        responseType: 'json',
        validateStatus(status) {
            return status < 500;
        },
    })
        .then((response: AxiosResponse<ApiBaseResponse<R>>) => response)
        .catch((error: AxiosError<ApiBaseResponse<R>>) => Promise.reject(error));

export const templateManagerRequest = <D>(
    endpointUrl: string,
    method: httpMethod,
    headers: RawAxiosRequestHeaders,
    data: D
): AxiosPromise =>
    axios({
        url: endpointUrl,
        method,
        headers,
        data,
        withCredentials: false,
        responseType: 'json',
        validateStatus(status) {
            return status < 500;
        },
    })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => error);

export const refreshTokenEvent = new CustomEvent('token-refreshed');
export const refreshToken = (): Promise<string> => {
    const endpointUrl = '/Authentication/refreshToken';

    const headers = {
        'Content-Type': 'application/json',
    };

    return httpRequest<null, RefreshTokenResponse>(endpointUrl, httpMethod.Post, headers).then(
        (response) => {
            if ('isAxiosError' in response) {
                return '';
            }

            const { success } = response.data;

            if (success === true) {
                const { token } = response.data.data.authenticationToken;
                setAuthToken(token);
                document.dispatchEvent(refreshTokenEvent);
                return token;
            }

            return '';
        }
    );
};

export const checkVersion = (): Promise<number> => {
    const verUrl = `${window.location.origin}/nafConnect/ver.json?${Date.now()}`;
    return axios
        .get<VersionResponse>(verUrl)
        .then((response: AxiosResponse<VersionResponse>) => response.data.appVer);
};

export function apiRequest<D, R>(
    endpointUrl: string,
    method: httpMethod,
    payload: D
): Promise<ApiResponse<R>> {
    const token = getAuthToken();

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    };

    return nafConnectAxiosInstance({
        baseURL: NAF_CONNECT_API_BASE_URL,
        url: endpointUrl,
        method,
        headers,
        data: payload,
        withCredentials: true,
        responseType: 'json',
        validateStatus(status) {
            return status < 500;
        },
    })
        .then((response: AxiosResponse<ApiBaseResponse<R>>) => response)
        .catch((error: AxiosError<ApiBaseResponse<R>>) => Promise.reject(error));
}

export const getAuthToken = (): string => localStorageHelper.get<string>('@nc_token') || '';

export function getRealEstateAgentId(): string {
    const token = getAuthToken();
    const { rid = '' } = parseJwt(token);

    return rid;
}

export const getRealEstateAgentJWTExpiration = (): number => {
    const token = getAuthToken();
    const { exp } = parseJwt(token);

    return exp;
};

export function getRealEstateAgentAccountType(): number {
    const token = getAuthToken();
    const { nc_at: ncAT } = parseJwt(token);

    return +ncAT;
}

export function getSocialSignOnStatus(): boolean {
    const token = getAuthToken();
    const { nc_sso: ncSso } = parseJwt(token);

    return ncSso?.toLowerCase() === 'true';
}

export const getAdminId = (): string => {
    const token = getAuthToken();
    const { nc_aid: adminId } = parseJwt(token);

    return adminId;
};

export const getNcRole = (): number => {
    const token = getAuthToken();
    const { nc_role: ncRole } = parseJwt(token);

    return +ncRole;
};

export const parseJwt = (token: string | null): JWT => {
    if (!token) return {} as JWT;

    const decoded = jwtDecode<JWT>(token);

    return decoded;
};
