import { useEffect, useState } from 'react';
import { getTemplate } from 'app/services/template/templates.service';

interface TemplateHook {
    templateContent: string;
}

export const useTemplate = (templateGuid: string): TemplateHook => {
    const [templateContent, setTemplateContent] = useState<string>('');

    useEffect(() => {
        if (templateGuid) {
            const fetchTemplate = async (): Promise<void> => {
                const template = await getTemplate(templateGuid);
                setTemplateContent(template || '');
            };
            fetchTemplate();
        }
    }, [templateGuid]);

    return {
        templateContent,
    };
};
