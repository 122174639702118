import styles from './toaster-content.module.scss';

export const ReferralSuccess = (firstName: string, lastName: string): JSX.Element => (
    <p className={styles.referralSuccess}>
        Thank you!
        <span className={styles.referralSuccess__name}>
            {firstName} {lastName}
        </span>
        has been referred to New American Funding.
    </p>
);

export const ReferralSuccessDealCreated = (firstName: string, lastName: string): JSX.Element => (
    <p className={styles.referralSuccess}>
        Your client
        <span className={styles.referralSuccess__name}>
            {firstName} {lastName}
        </span>
        has received an email to start the loan application.
    </p>
);
