import { axiosBaseApiQuery } from '../axiosBaseQuery';
import { NAF_CONNECT_API_BASE_URL } from 'app/shared/helpers/settings.helper';
import { PushNotificationSubscription } from './push-notifications.model';
import { getRealEstateAgentId } from 'app/services';
import { httpMethod } from 'app/services/_httpClient';
import { createApi } from '@reduxjs/toolkit/query/react';

export const pushNotificationsApi = createApi({
    reducerPath: 'pushNotifications-api',
    baseQuery: axiosBaseApiQuery({
        baseUrl: `${NAF_CONNECT_API_BASE_URL}/realEstateAgents/`,
    }),
    endpoints: (builder) => ({
        getSubscribers: builder.query<PushNotificationSubscription[], void>({
            query: () => ({
                url: `${getRealEstateAgentId()}/pushNotifications/subscriptions`,
                method: httpMethod.Get,
            }),
            transformResponse: (response: { data: PushNotificationSubscription[] }) =>
                response.data,
        }),
    }),
});

export const { useGetSubscribersQuery } = pushNotificationsApi;
