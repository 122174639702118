import {
    changePassword,
    existenceCheck,
    forgotPassword,
    logIn,
    loginWithToken,
    logOut,
    register,
    resetPassword,
    sendCode,
    validateEmailAddress,
    verifyCode,
} from 'app/services';
import {
    ChangePasswordRequest,
    ExistenceCheckRequest,
    ExistenceCheckResponse,
    ForgotPasswordRequest,
    LoginRequest,
    LoginWithTokenRequest,
    RegisterRequest,
    ResetPasswordRequest,
    SendCodeRequest,
    ValidateEmailAddressRequest,
    VerifyCodeRequest,
} from 'app/services/auth/models';
import { AsyncRequestStatus } from 'app/shared/enums';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { toasterError, toasterSuccess } from 'app/shared/helpers';

export interface AuthState {
    loginErrorText: string;
    isLoginSuccessful: boolean;
    isRegistrationSuccessful: boolean;
    isValidEmailAddress: boolean;
    existenceCheckData: ExistenceCheckResponse | null;
    resetPasswordEmail: string;
    twoFactorVerified: boolean | null;
    userPhoneNumber: string | null;
    twoFactorVerificationCode: string;
    statusLogIn: AsyncRequestStatus;
    statusLogOut: AsyncRequestStatus;
    statusRegister: AsyncRequestStatus;
    statusForgotPassword: AsyncRequestStatus;
    statusChangePassword: AsyncRequestStatus;
    statusResetPassword: AsyncRequestStatus;
    statusValidateEmailAddress: AsyncRequestStatus;
    statusLogInWithToken: AsyncRequestStatus;
    statusRegisterWithToken: AsyncRequestStatus;
    statusExistenceCheck: AsyncRequestStatus;
    statusSendCode: AsyncRequestStatus;
    statusVerifyCode: AsyncRequestStatus;
}

const initialState: AuthState = {
    loginErrorText: '',
    isLoginSuccessful: false,
    isRegistrationSuccessful: false,
    isValidEmailAddress: true,
    existenceCheckData: null,
    resetPasswordEmail: '',
    twoFactorVerified: null,
    userPhoneNumber: null,
    twoFactorVerificationCode: '',
    statusLogIn: AsyncRequestStatus.Idle,
    statusLogOut: AsyncRequestStatus.Idle,
    statusRegister: AsyncRequestStatus.Idle,
    statusForgotPassword: AsyncRequestStatus.Idle,
    statusChangePassword: AsyncRequestStatus.Idle,
    statusResetPassword: AsyncRequestStatus.Idle,
    statusValidateEmailAddress: AsyncRequestStatus.Idle,
    statusLogInWithToken: AsyncRequestStatus.Idle,
    statusRegisterWithToken: AsyncRequestStatus.Idle,
    statusExistenceCheck: AsyncRequestStatus.Idle,
    statusSendCode: AsyncRequestStatus.Idle,
    statusVerifyCode: AsyncRequestStatus.Idle,
};

export const LoginAsync = createAsyncThunk('auth/Login', async (request: LoginRequest) => {
    const response = await logIn(request);
    return response;
});

export const LoginWithTokenAsync = createAsyncThunk(
    'auth/LoginWithToken',
    async (request: LoginWithTokenRequest) => {
        const response = await loginWithToken(request);
        return response;
    }
);

export const RegisterWithToken = createAsyncThunk(
    'auth/RegisterWithToken',
    async (request: LoginWithTokenRequest) => {
        const response = await loginWithToken(request);
        return response;
    }
);

export const LogoutAsync = createAsyncThunk('auth/Logout', async () => {
    const response = await logOut();
    return response;
});

export const RegisterAsync = createAsyncThunk('auth/Register', async (request: RegisterRequest) => {
    const response = await register(request);
    return response;
});

export const ForgotPasswordAsync = createAsyncThunk(
    'auth/ForgotPassword',
    async (request: ForgotPasswordRequest) => {
        const response = await forgotPassword(request);
        return response;
    }
);

export const ChangePasswordAsync = createAsyncThunk(
    'auth/ChangePassword',
    async (request: ChangePasswordRequest) => {
        const response = await changePassword(request);
        return response;
    }
);

export const ResetPasswordAsync = createAsyncThunk(
    'auth/ResetPassword',
    async (request: ResetPasswordRequest) => {
        const response = await resetPassword(request);
        return response;
    }
);

export const ValidateEmailAddressAsync = createAsyncThunk(
    'auth/ValidateEmail',
    async (request: ValidateEmailAddressRequest) => {
        const response = await validateEmailAddress(request);
        return response;
    }
);

export const ExistenceCheckAsync = createAsyncThunk(
    'auth/ExistenceCheck',
    async (request: ExistenceCheckRequest) => {
        const response = await existenceCheck(request);
        return response;
    }
);

export const SendCodeAsync = createAsyncThunk('auth/SendCode', async (request: SendCodeRequest) => {
    const response = await sendCode(request);
    return response;
});

export const VerifyCodeAsync = createAsyncThunk(
    'auth/VerifyCode',
    async (request: VerifyCodeRequest) => {
        const response = await verifyCode(request);
        return response;
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoginErrorText: (state, action: PayloadAction<string>) => {
            state.loginErrorText = action.payload;
        },
        resetIsValidEmailAddress: (state) => {
            if (!state.isValidEmailAddress) state.isValidEmailAddress = true;
        },
        resetIstwoFactorVerified: (state) => {
            state.twoFactorVerified = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // LOGIN
            .addCase(LoginAsync.pending, (state) => {
                state.statusLogIn = AsyncRequestStatus.Pending;
            })
            .addCase(LoginAsync.fulfilled, (state, action) => {
                state.statusLogIn = AsyncRequestStatus.Fulfilled;

                if (action.payload) {
                    const { status } = action.payload;
                    const { success, data, errors } = action.payload.data;

                    if (data && data.phoneNumber != null) {
                        state.userPhoneNumber = data.phoneNumber;
                    }

                    if (errors != null) {
                        if (errors.some((e) => e === 'Invalid sms code.')) {
                            state.twoFactorVerified = false;
                            state.isLoginSuccessful = false;
                            state.statusLogIn = AsyncRequestStatus.Idle;
                        }
                    }
                    if (status === 200 && success === true) {
                        if (action.payload.data.data.authenticationToken) {
                            state.isLoginSuccessful = true;
                            state.twoFactorVerified = action.payload.data.data.isValidated;
                        } else {
                            state.twoFactorVerified = action.payload.data.data.isValidated;
                        }
                    }

                    if (status === 401) {
                        let errorText = 'Invalid Username or Password.';

                        if (data) {
                            const { accountLocked, provider } = data;

                            if (provider) {
                                errorText = `Account already exists for this email address. 
								Please log in through the ${provider} icon above.`;
                            }

                            if (accountLocked) {
                                errorText = `Account is Locked. To unlock your account, please click "Forgot Password" below and follow the reset your password steps.`;
                            }
                        }

                        state.loginErrorText = errorText;
                    }
                }
            })
            .addCase(LoginAsync.rejected, (state) => {
                state.statusLogIn = AsyncRequestStatus.Rejected;
            })
            // LOGIN WITH TOKEN
            .addCase(LoginWithTokenAsync.pending, (state) => {
                state.statusLogInWithToken = AsyncRequestStatus.Pending;
            })
            .addCase(LoginWithTokenAsync.fulfilled, (state, action) => {
                state.statusLogInWithToken = AsyncRequestStatus.Fulfilled;

                if (action.payload) {
                    const { status } = action.payload;
                    const { success, data } = action.payload.data;

                    state.twoFactorVerified = data.isValidated;

                    if (status === 200 && success === true) {
                        state.isLoginSuccessful = true;
                    }
                    if (status === 401) {
                        if (data) {
                            const { accountLocked, hasSSOEmail } = data;

                            if (!hasSSOEmail && !accountLocked) {
                                state.loginErrorText = `Social sign up selected did not share your email address, 
								which is required to sign up with NAF Connect. 
								Please sign up manually by completing the form below.`;
                            }

                            if (accountLocked) {
                                state.loginErrorText = `Account is Locked. To unlock your account, please click "Forgot Password" below and follow the reset your password steps.`;
                            }
                        }
                    }
                }
            })
            .addCase(LoginWithTokenAsync.rejected, (state) => {
                state.statusLogInWithToken = AsyncRequestStatus.Rejected;
            })
            // LOGOUT
            .addCase(LogoutAsync.pending, (state) => {
                state.statusLogOut = AsyncRequestStatus.Pending;
            })
            .addCase(LogoutAsync.fulfilled, (state) => {
                state.statusLogOut = AsyncRequestStatus.Fulfilled;
            })
            .addCase(LogoutAsync.rejected, (state) => {
                state.statusLogOut = AsyncRequestStatus.Rejected;
            })
            // REGISTER
            .addCase(RegisterAsync.pending, (state) => {
                state.statusRegister = AsyncRequestStatus.Pending;
            })
            .addCase(RegisterAsync.fulfilled, (state, action) => {
                state.statusRegister = AsyncRequestStatus.Fulfilled;

                if (action.payload) {
                    const { status } = action.payload;
                    const { success, errors } = action.payload.data;

                    if (errors != null) {
                        if (errors.some((e) => e === 'Invalid sms code.')) {
                            state.twoFactorVerified = false;
                            state.isRegistrationSuccessful = false;
                        }
                    }

                    if (status === 200 && success === true) {
                        state.isRegistrationSuccessful = true;
                    }
                }
            })
            .addCase(RegisterAsync.rejected, (state) => {
                state.statusRegisterWithToken = AsyncRequestStatus.Rejected;
            })
            // REGISTER WITH TOKEN
            .addCase(RegisterWithToken.pending, (state) => {
                state.statusRegisterWithToken = AsyncRequestStatus.Pending;
            })
            .addCase(RegisterWithToken.fulfilled, (state, action) => {
                state.statusRegisterWithToken = AsyncRequestStatus.Fulfilled;

                if (action.payload) {
                    const { status } = action.payload;
                    const { success } = action.payload.data;

                    if (status === 200 && success === true) {
                        state.isRegistrationSuccessful = true;
                    }
                }
            })
            .addCase(RegisterWithToken.rejected, (state) => {
                state.statusRegisterWithToken = AsyncRequestStatus.Rejected;
            })
            // FORGOT PASSWORD
            .addCase(ForgotPasswordAsync.pending, (state) => {
                state.statusForgotPassword = AsyncRequestStatus.Pending;
            })
            .addCase(ForgotPasswordAsync.fulfilled, (state) => {
                state.statusForgotPassword = AsyncRequestStatus.Fulfilled;
            })
            .addCase(ForgotPasswordAsync.rejected, (state) => {
                state.statusForgotPassword = AsyncRequestStatus.Rejected;
                toasterError('Error');
            })
            // CHANGE PASSWORD
            .addCase(ChangePasswordAsync.pending, (state) => {
                state.statusChangePassword = AsyncRequestStatus.Pending;
            })
            .addCase(ChangePasswordAsync.fulfilled, (state, action) => {
                state.statusChangePassword = AsyncRequestStatus.Fulfilled;

                if (action.payload) {
                    const { status } = action.payload;

                    if (status === 200) {
                        toasterSuccess('Success');
                    } else {
                        toasterError('Error');
                    }
                } else {
                    toasterError('Error');
                }
            })
            .addCase(ChangePasswordAsync.rejected, (state) => {
                state.statusChangePassword = AsyncRequestStatus.Rejected;
                toasterError('Error');
            })
            // RESET PASSWORD
            .addCase(ResetPasswordAsync.pending, (state) => {
                state.statusResetPassword = AsyncRequestStatus.Pending;
            })
            .addCase(ResetPasswordAsync.fulfilled, (state, action) => {
                state.statusResetPassword = AsyncRequestStatus.Fulfilled;

                if (action.payload && action.payload.data.success) {
                    state.resetPasswordEmail = action.payload.data.data;
                }
            })
            .addCase(ResetPasswordAsync.rejected, (state) => {
                state.statusResetPassword = AsyncRequestStatus.Rejected;
            })
            // VALIDATE EMAIL ADDRESS
            .addCase(ValidateEmailAddressAsync.pending, (state) => {
                state.statusValidateEmailAddress = AsyncRequestStatus.Pending;
            })
            .addCase(ValidateEmailAddressAsync.fulfilled, (state, action) => {
                state.statusValidateEmailAddress = AsyncRequestStatus.Fulfilled;

                if (action.payload) {
                    state.isValidEmailAddress = action.payload.data.data.isValid;
                }
            })
            .addCase(ValidateEmailAddressAsync.rejected, (state) => {
                state.statusValidateEmailAddress = AsyncRequestStatus.Rejected;
            })
            // EXISTENCE CHECK
            .addCase(ExistenceCheckAsync.pending, (state) => {
                state.statusExistenceCheck = AsyncRequestStatus.Pending;
            })
            .addCase(ExistenceCheckAsync.fulfilled, (state, action) => {
                state.statusExistenceCheck = AsyncRequestStatus.Fulfilled;

                if (action.payload) {
                    state.existenceCheckData = action.payload.data.data;
                }
            })
            .addCase(ExistenceCheckAsync.rejected, (state) => {
                state.statusExistenceCheck = AsyncRequestStatus.Rejected;
            })
            // SEND CODE
            .addCase(SendCodeAsync.pending, (state) => {
                state.statusSendCode = AsyncRequestStatus.Pending;
                state.twoFactorVerified = null;
            })
            .addCase(SendCodeAsync.fulfilled, (state) => {
                state.statusSendCode = AsyncRequestStatus.Fulfilled;
            })
            .addCase(SendCodeAsync.rejected, (state) => {
                state.statusSendCode = AsyncRequestStatus.Rejected;
            })
            // VERIFY CODE
            .addCase(VerifyCodeAsync.pending, (state) => {
                state.statusVerifyCode = AsyncRequestStatus.Pending;
                state.twoFactorVerified = null;
            })
            .addCase(VerifyCodeAsync.fulfilled, (state, action) => {
                state.statusVerifyCode = AsyncRequestStatus.Fulfilled;

                if (action.payload) {
                    state.twoFactorVerified = action.payload.data.data.isValid.success;
                    state.twoFactorVerificationCode = action.payload.data.data.isValid.data;
                }
            })
            .addCase(VerifyCodeAsync.rejected, (state) => {
                state.statusVerifyCode = AsyncRequestStatus.Rejected;
            });
    },
});

export const { setLoginErrorText, resetIsValidEmailAddress, resetIstwoFactorVerified } =
    authSlice.actions;
export const AuthSelector = (state: RootState): AuthState => state.auth;

export const statusRegisterSelector = (state: RootState): AsyncRequestStatus =>
    state.auth.statusRegister;
export const statusLoginSelector = (state: RootState): AsyncRequestStatus => state.auth.statusLogIn;
export const statusForgotPasswordSelector = (state: RootState): AsyncRequestStatus =>
    state.auth.statusForgotPassword;
export const statusChangePasswordSelector = (state: RootState): AsyncRequestStatus =>
    state.auth.statusChangePassword;
export const statusResetPasswordSelector = (state: RootState): AsyncRequestStatus =>
    state.auth.statusResetPassword;
export const statusValidateEmailAddressSelector = (state: RootState): AsyncRequestStatus =>
    state.auth.statusValidateEmailAddress;
export const isValidEmailAddressSelector = (state: RootState): boolean =>
    state.auth.isValidEmailAddress;
export const statusExistenceCheckSelector = (state: RootState): AsyncRequestStatus =>
    state.auth.statusExistenceCheck;
export const statusLogInWithTokenSelector = (state: RootState): AsyncRequestStatus =>
    state.auth.statusLogInWithToken;
export const statusRegisterWithTokenSelector = (state: RootState): AsyncRequestStatus =>
    state.auth.statusRegisterWithToken;
export const statusVerifyCodeSelector = (state: RootState): AsyncRequestStatus =>
    state.auth.statusVerifyCode;
export const existenceCheckDataSelector = (state: RootState): ExistenceCheckResponse | null =>
    state.auth.existenceCheckData;
export const twoFactorVerifiedSelector = (state: RootState): boolean | null =>
    state.auth.twoFactorVerified;
export const twoFactorVerificationCodeSelector = (state: RootState): string =>
    state.auth.twoFactorVerificationCode;

export const userPhoneNumberSelector = (state: RootState): string | null =>
    state.auth.userPhoneNumber;

export default authSlice.reducer;
