import { ReactElement, SVGProps } from 'react';

const IconInfoIcon = ({ width, fill }: SVGProps<SVGPathElement>): ReactElement => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve" width={width}>
        <path
            d="M.3 10c0 2.7.9 5 2.8 6.9s4.2 2.8 6.9 2.8 5-.9 6.9-2.8 2.8-4.2 2.8-6.9-.9-5-2.8-6.9C15 1.3 12.7.3 10 .3s-5 .9-6.9 2.8C1.3 5 .3 7.3.3 10zm11.3-3.8c0 .4-.2.8-.5 1.2-.3.3-.7.5-1.2.5-.4 0-.8-.2-1.2-.5-.2-.3-.3-.7-.3-1.2 0-.4.2-.8.5-1.2.3-.2.7-.4 1.1-.4s.8.2 1.2.5c.3.3.4.7.4 1.1zm.1 8.8H8.3c-.3 0-.5-.2-.5-.5v-.9c0-.3.2-.5.5-.5h.5v-2.5h-.5c-.3 0-.5-.2-.5-.5v-.9c0-.3.2-.5.5-.5h2.5c.3 0 .5.2.5.5v3.9h.5c.3 0 .5.2.5.5v.9c-.1.3-.3.5-.6.5z"
            style={{
                fill,
            }}
        />
    </svg>
);

export default IconInfoIcon;
