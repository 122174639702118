import axios from 'axios';
import { nafConnectAxiosInstance } from 'app/services/axios-instance';
import { getAuthToken } from 'app/services';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';

export interface NcAxiosRequestConfig extends AxiosRequestConfig {
    suppressRedirect?: boolean;
}

export const axiosBaseQuery =
    (
        { baseUrl }: { baseUrl: string } = { baseUrl: '' }
    ): BaseQueryFn<
        {
            url: string;
            method: AxiosRequestConfig['method'];
            data?: AxiosRequestConfig['data'];
            params?: AxiosRequestConfig['params'];
            headers?: AxiosRequestConfig['headers'];
        },
        unknown,
        unknown
    > =>
    async ({ url, method, data, params, headers }) => {
        try {
            const result = await axios({
                url: baseUrl + url,
                method,
                data,
                params,
                headers,
                withCredentials: true,
            });
            return { data: result.data };
        } catch (axiosError) {
            const err = axiosError as AxiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            };
        }
    };

export const axiosBaseApiQuery =
    (
        { baseUrl }: { baseUrl: string } = { baseUrl: '' }
    ): BaseQueryFn<NcAxiosRequestConfig, unknown, unknown> =>
    async ({ url, method, data, params, suppressRedirect }) => {
        try {
            const token = getAuthToken();
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };

            const result = await nafConnectAxiosInstance({
                url: baseUrl + url,
                method,
                data,
                params,
                headers,
                suppressRedirect,
                withCredentials: true,
            } as NcAxiosRequestConfig);
            return { data: result.data };
        } catch (axiosError) {
            const err = axiosError as AxiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            };
        }
    };
