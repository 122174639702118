import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
        tablet: true;
        desktop: true;
    }
}

export default createTheme({
    breakpoints: {
        values: {
            xs: 576,
            sm: 768,
            md: 992,
            lg: 1200,
            xl: 1440,
            tablet: 415,
            desktop: 821,
        },
    },
});
