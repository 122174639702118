import { createApi } from '@reduxjs/toolkit/query/react';
import { NAF_CONNECT_API_BASE_URL } from 'app/shared/helpers/settings.helper';
import { axiosBaseApiQuery } from 'app/store/api/axiosBaseQuery';
import { LoanOfficer } from 'app/shared/models';
import { httpMethod } from 'app/services/_httpClient';

export const loanOfficerApi = createApi({
    reducerPath: 'loanOfficer-api',
    baseQuery: axiosBaseApiQuery({ baseUrl: NAF_CONNECT_API_BASE_URL }),
    endpoints: (builder) => ({
        getLoanOfficerById: builder.query<LoanOfficer, string>({
            query: (id) => ({
                url: `/loanOfficers/${id}`,
                method: httpMethod.Get,
            }),
            transformResponse: (response: { data: LoanOfficer }) => response.data,
        }),
    }),
});

export const { useGetLoanOfficerByIdQuery } = loanOfficerApi;
