import { createApi } from '@reduxjs/toolkit/query/react';
import {
    ApiBaseResponse,
    RefreshTokenResponse,
} from 'app/services/_httpClient/models/http-client.model';
import { AdminLoginRequest, AdminLoginResponse } from './admin.models';
import { httpMethod } from 'app/services/_httpClient';
import { axiosBaseApiQuery, axiosBaseQuery } from '../axiosBaseQuery';
import { NAF_CONNECT_API_BASE_URL, NAF_CONNECT_API_KEY } from 'app/shared/helpers/settings.helper';
import { RealEstateAgent } from 'app/shared/models';
import { setAuthToken } from 'app/services';
import {
    PasswordResetRequest,
    PasswordResetResponse,
    RequestCode,
    VerificationCodeResponse,
} from './models';
import { toasterError, toasterSuccess } from 'app/shared/helpers';

export const adminApi = createApi({
    reducerPath: 'admin-api',
    baseQuery: axiosBaseQuery({ baseUrl: `${NAF_CONNECT_API_BASE_URL}/admin/` }),
    endpoints: (builder) => ({
        signin: builder.mutation<AdminLoginResponse, AdminLoginRequest>({
            query: (data) => ({
                url: 'signin',
                method: httpMethod.Post,
                headers: {
                    'X-API-Key': NAF_CONNECT_API_KEY,
                },
                data,
            }),
            transformResponse: (response: { data: AdminLoginResponse }) => response.data,
            async onQueryStarted(_arg, { queryFulfilled }) {
                const { data: tokenResponse } = await queryFulfilled;
                if (tokenResponse) {
                    const { token } = tokenResponse.authenticationToken;
                    setAuthToken(token);
                }
            },
        }),
    }),
});

export const adminAuthApi = createApi({
    reducerPath: 'admin-authApi',
    baseQuery: axiosBaseApiQuery({ baseUrl: `${NAF_CONNECT_API_BASE_URL}/admin/` }),
    endpoints: (builder) => ({
        reaSearch: builder.query<RealEstateAgent[], string>({
            query: (data) => ({
                url: data ? `reaSearch?emailAddress=${encodeURIComponent(data)}` : 'reaSearch',
                method: httpMethod.Get,
            }),
            transformResponse: (response: { data: RealEstateAgent[] }) => response.data,
        }),
        reaSignin: builder.mutation<RefreshTokenResponse, AdminLoginRequest>({
            query: (data) => ({
                url: 'reaSignin',
                method: httpMethod.Post,
                data,
            }),
            transformResponse: (response: { data: RefreshTokenResponse }) => response.data,
            async onQueryStarted(_arg, { queryFulfilled }) {
                const { data: tokenResponse } = await queryFulfilled;
                if (tokenResponse) {
                    const { token } = tokenResponse.authenticationToken;
                    setAuthToken(token);
                }
            },
        }),
        sendVerificationCode: builder.mutation<VerificationCodeResponse, RequestCode>({
            query: (data) => ({
                url: 'verificationCodes',
                method: httpMethod.Post,
                data,
            }),
            transformResponse: (response: { data: VerificationCodeResponse }) => response.data,
            onQueryStarted: async () => {
                try {
                    toasterSuccess('Success');
                } catch {
                    toasterError('Error');
                }
            },
        }),
        searchVerificationCode: builder.query<VerificationCodeResponse, string>({
            query: (data) => ({
                url: `verificationCodes?phoneNumber=${data}`,
                method: httpMethod.Get,
            }),
            transformResponse: (response: { data: VerificationCodeResponse }) => response.data,
        }),
        reaPassword: builder.query<ApiBaseResponse<PasswordResetResponse>, PasswordResetRequest>({
            query: (data) => ({
                url: 'reaPassword',
                method: httpMethod.Put,
                data,
            }),
        }),
    }),
});

export const { useSigninMutation } = adminApi;
export const {
    useLazyReaSearchQuery,
    useReaSigninMutation,
    useSendVerificationCodeMutation,
    useSearchVerificationCodeQuery,
    useLazySearchVerificationCodeQuery,
    useLazyReaPasswordQuery,
} = adminAuthApi;
