import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { Registration } from 'app/shared/models';

interface LandingPageState {
    isRegistrationModalOpen: boolean;
    isLoginModalOpen: boolean;
    registrationDetails: Registration;
    loginDetails: {
        agentEmailAddress: string;
    };
}

const initialState: LandingPageState = {
    isRegistrationModalOpen: false,
    isLoginModalOpen: false,
    registrationDetails: {
        agentFirstName: '',
        agentLastName: '',
        agentPhone: '',
        agentEmail: '',
        agentStateCode: '',
        nafHomesId: '',
        reaSource: '',
    },
    loginDetails: {
        agentEmailAddress: '',
    },
};

export const landingPageSlice = createSlice({
    name: 'landingPage',
    initialState,
    reducers: {
        toggleRegistrationModal(state) {
            state.isRegistrationModalOpen = !state.isRegistrationModalOpen;
        },
        toggleloginModal(state) {
            state.isLoginModalOpen = !state.isLoginModalOpen;
        },
        openLoginModal(state) {
            state.isLoginModalOpen = true;
        },
        openRegistrationModal(state) {
            state.isRegistrationModalOpen = true;
        },
        setRegistrationDetails(state, action) {
            state.registrationDetails = action.payload;
        },
        setLoginDetails(state, action) {
            state.loginDetails = action.payload;
        },
    },
});

export const isRegistrationModalOpenSelector = (state: RootState): boolean =>
    state.landingPage.isRegistrationModalOpen;
export const isLoginModalOpenSelector = (state: RootState): boolean =>
    state.landingPage.isLoginModalOpen;
export const registrationDetailsSelector = (state: RootState): Registration =>
    state.landingPage.registrationDetails;
export const loginDetailsSelector = (state: RootState): LandingPageState['loginDetails'] =>
    state.landingPage.loginDetails;

export const {
    toggleRegistrationModal,
    toggleloginModal,
    openLoginModal,
    openRegistrationModal,
    setRegistrationDetails,
    setLoginDetails,
} = landingPageSlice.actions;

export default landingPageSlice.reducer;
