import { getRealEstateAgentId, httpMethod } from 'app/services/_httpClient';
import { localStorageHelper, toasterError, toasterSuccess } from 'app/shared/helpers';
import {
    EmailSubscriptionSetting,
    LoanOfficer,
    NotificationSetting,
    RealEstateAgent,
} from 'app/shared/models';
import { axiosBaseApiQuery } from 'app/store/api/axiosBaseQuery';

import { ImageUploadRequest } from './models/image-upload-request.model';
import {
    AddPreferredLoanOfficerRequest,
    RemovePreferredLoanOfficerRequest,
} from './models/preferred-loan-officers.model';
import { CompanyInformationRequest } from './models/update-company-information-request.model';
import { PersonalInformationRequest } from './models/update-personal-information-request.model';
import { NAF_CONNECT_API_BASE_URL } from 'app/shared/helpers/settings.helper';
import { createApi } from '@reduxjs/toolkit/query/react';
import { ModalType } from 'app/shared/enums/modal-type.enum';
import { UpdateModalActivityRequest } from './models/update-modal-activity-request.model';
import { RealEstateagentPreference } from './models/real-estate-agent-preference.model';

export const realEstateAgentApi = createApi({
    reducerPath: 'realEstateAgent-api',
    baseQuery: axiosBaseApiQuery({
        baseUrl: `${NAF_CONNECT_API_BASE_URL}/realEstateAgents/`,
    }),
    tagTypes: ['REA_UPDATE'],
    endpoints: (builder) => ({
        getRealEstateAgent: builder.query<RealEstateAgent, void>({
            query: () => ({
                url: `${getRealEstateAgentId()}`,
                method: httpMethod.Get,
            }),
            providesTags: ['REA_UPDATE'],
            transformResponse: (response: { data: RealEstateAgent }) => response.data,
            async onQueryStarted(arg, { queryFulfilled }) {
                const { data: reaFromServer } = await queryFulfilled;
                localStorageHelper.set(`${getRealEstateAgentId()}-rea`, reaFromServer);
            },
        }),
        getNotificationSettings: builder.query<NotificationSetting[], void>({
            query: () => ({
                url: `${getRealEstateAgentId()}/notificationSettings`,
                method: httpMethod.Get,
            }),
            transformResponse: (response: { data: NotificationSetting[] }) => response.data,
        }),
        getEmailSubscriptionSettings: builder.query<EmailSubscriptionSetting[], void>({
            query: () => ({
                url: `${getRealEstateAgentId()}/emailSubscriptionSettings`,
                method: httpMethod.Get,
            }),
            transformResponse: (response: { data: EmailSubscriptionSetting[] }) => response.data,
        }),
        getPreferredLoanOfficers: builder.query<LoanOfficer[] | null, void>({
            query: () => ({
                url: `${getRealEstateAgentId()}/preferredLoanOfficers`,
                method: httpMethod.Get,
            }),
            transformResponse: (response: { data: LoanOfficer[] | null }) => response.data,
        }),
        getModals: builder.query<ModalType[], void>({
            query: () => ({
                url: `${getRealEstateAgentId()}/modalTypes`,
                method: httpMethod.Get,
            }),
            transformResponse: (response: { data: ModalType[] }) => response.data,
        }),
        updateModalDismissal: builder.mutation<ModalType, UpdateModalActivityRequest>({
            query: (data) => ({
                url: `${getRealEstateAgentId()}/modalActivity`,
                method: httpMethod.Put,
                data,
            }),
            transformResponse: (response: { data: ModalType }) => response.data,
            async onQueryStarted(arg, { queryFulfilled }) {
                await queryFulfilled;
            },
        }),
        getLazyPreferredLoanOfficers: builder.query<LoanOfficer[] | null, void>({
            query: () => ({
                url: `${getRealEstateAgentId()}/preferredLoanOfficers`,
                method: httpMethod.Get,
            }),
            transformResponse: (response: { data: LoanOfficer[] | null }) => response.data,
        }),
        addPreferredLoanOfficers: builder.mutation<LoanOfficer[], AddPreferredLoanOfficerRequest>({
            query: (data) => ({
                url: `${getRealEstateAgentId()}/preferredLoanOfficers`,
                method: httpMethod.Post,
                data,
            }),
            transformResponse: (response: { data: LoanOfficer[] }) => response.data,
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data: preferredLoanOfficers } = await queryFulfilled;

                    dispatch(
                        realEstateAgentApi.util.updateQueryData(
                            'getPreferredLoanOfficers',
                            undefined,
                            () => preferredLoanOfficers
                        )
                    );

                    toasterSuccess('Success');
                } catch {
                    toasterError('Error');
                }
            },
        }),
        removePreferredLoanOfficers: builder.mutation<
            LoanOfficer[],
            RemovePreferredLoanOfficerRequest
        >({
            query: (data) => ({
                url: `${getRealEstateAgentId()}/preferredLoanOfficers/${data.loanOfficerId}`,
                method: httpMethod.Delete,
                data,
            }),
            transformResponse: (response: { data: LoanOfficer[] }) => response.data,
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                const { data: preferredLoanOfficers } = await queryFulfilled;

                dispatch(
                    realEstateAgentApi.util.updateQueryData(
                        'getPreferredLoanOfficers',
                        undefined,
                        () => preferredLoanOfficers
                    )
                );
            },
        }),
        updateNotificationSettings: builder.mutation<NotificationSetting[], NotificationSetting[]>({
            query: (data) => ({
                url: `${getRealEstateAgentId()}/notificationSettings`,
                method: httpMethod.Put,
                data,
            }),
            transformResponse: (response: { data: NotificationSetting[] }) => response.data,
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedSettings } = await queryFulfilled;

                    dispatch(
                        realEstateAgentApi.util.updateQueryData(
                            'getNotificationSettings',
                            undefined,
                            () => [...updatedSettings]
                        )
                    );

                    toasterSuccess('Success');
                } catch {
                    toasterError('Error');
                }
            },
        }),
        updateEmailSubscriptionSettings: builder.mutation<
            EmailSubscriptionSetting[],
            EmailSubscriptionSetting[]
        >({
            query: (data) => ({
                url: `${getRealEstateAgentId()}/emailSubscriptionSettings`,
                method: httpMethod.Put,
                data,
            }),
            transformResponse: (response: { data: EmailSubscriptionSetting[] }) => response.data,
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedSettings } = await queryFulfilled;

                    dispatch(
                        realEstateAgentApi.util.updateQueryData(
                            'getEmailSubscriptionSettings',
                            undefined,
                            () => [...updatedSettings]
                        )
                    );

                    toasterSuccess('Success');
                } catch {
                    toasterError('Error');
                }
            },
        }),
        updateCompanyInformation: builder.mutation<RealEstateAgent, CompanyInformationRequest>({
            query: (data) => ({
                url: `${getRealEstateAgentId()}/companyInformation`,
                method: httpMethod.Put,
                data,
            }),
            transformResponse: (response: { data: RealEstateAgent }) => response.data,
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data: rea } = await queryFulfilled;

                    dispatch(
                        realEstateAgentApi.util.updateQueryData(
                            'getRealEstateAgent',
                            undefined,
                            () => ({ ...rea })
                        )
                    );
                    localStorageHelper.set(`${getRealEstateAgentId()}-rea`, rea);

                    toasterSuccess('Success');
                } catch {
                    toasterError('Error');
                }
            },
        }),
        updatePersonalInformation: builder.mutation<RealEstateAgent, PersonalInformationRequest>({
            query: (data) => ({
                url: `${getRealEstateAgentId()}/personalInformation`,
                method: httpMethod.Put,
                data,
            }),
            transformResponse: (response: { data: RealEstateAgent }) => response.data,
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data: rea } = await queryFulfilled;

                    dispatch(
                        realEstateAgentApi.util.updateQueryData(
                            'getRealEstateAgent',
                            undefined,
                            () => ({ ...rea })
                        )
                    );
                    localStorageHelper.set(`${getRealEstateAgentId()}-rea`, rea);

                    toasterSuccess('Success');
                } catch {
                    toasterError('Error');
                }
            },
        }),
        updateProfilePicture: builder.mutation<RealEstateAgent, ImageUploadRequest>({
            query: (data) => ({
                url: `${getRealEstateAgentId()}/profilePicture`,
                method: httpMethod.Put,
                data: { base64FileContents: data.base64 },
            }),
            transformResponse: (response: { data: RealEstateAgent }) => response.data,
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data: rea } = await queryFulfilled;

                    dispatch(
                        realEstateAgentApi.util.updateQueryData(
                            'getRealEstateAgent',
                            undefined,
                            () => ({ ...rea })
                        )
                    );
                    localStorageHelper.set(`${getRealEstateAgentId()}-rea`, rea);

                    toasterSuccess('Success');
                } catch {
                    toasterError('Error');
                }
            },
        }),
        updateCompanyLogo: builder.mutation<RealEstateAgent, ImageUploadRequest>({
            query: (data) => ({
                url: `${getRealEstateAgentId()}/companyLogo`,
                method: httpMethod.Put,
                data: { base64FileContents: data.base64 },
            }),
            transformResponse: (response: { data: RealEstateAgent }) => response.data,
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data: rea } = await queryFulfilled;

                    dispatch(
                        realEstateAgentApi.util.updateQueryData(
                            'getRealEstateAgent',
                            undefined,
                            () => ({ ...rea })
                        )
                    );
                    localStorageHelper.set(`${getRealEstateAgentId()}-rea`, rea);

                    toasterSuccess('Success');
                } catch {
                    toasterError('Error');
                }
            },
        }),
        updatePreferences: builder.mutation<RealEstateAgent, RealEstateagentPreference>({
            query: (data) => ({
                url: `${getRealEstateAgentId()}/preferences`,
                method: httpMethod.Put,
                data,
            }),
            invalidatesTags: ['REA_UPDATE'],
            transformResponse: (response: { data: RealEstateAgent }) => response.data,
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data: rea } = await queryFulfilled;

                    dispatch(
                        realEstateAgentApi.util.updateQueryData(
                            'getRealEstateAgent',
                            undefined,
                            () => ({ ...rea })
                        )
                    );

                    localStorageHelper.set(`${getRealEstateAgentId()}-rea`, rea);
                } catch {
                    toasterError('Error');
                }
            },
        }),
    }),
});

export const {
    useGetRealEstateAgentQuery,
    useGetNotificationSettingsQuery,
    useGetEmailSubscriptionSettingsQuery,
    useGetPreferredLoanOfficersQuery,
    useLazyGetPreferredLoanOfficersQuery,
    useAddPreferredLoanOfficersMutation,
    useRemovePreferredLoanOfficersMutation,
    useGetModalsQuery,
    useUpdateModalDismissalMutation,
    useUpdateNotificationSettingsMutation,
    useUpdateEmailSubscriptionSettingsMutation,
    useUpdateCompanyInformationMutation,
    useUpdatePersonalInformationMutation,
    useUpdateProfilePictureMutation,
    useUpdateCompanyLogoMutation,
    useUpdatePreferencesMutation,
} = realEstateAgentApi;
