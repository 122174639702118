import { createApi } from '@reduxjs/toolkit/query/react';
import { NAF_CONNECT_API_BASE_URL, NAF_CONNECT_API_KEY } from 'app/shared/helpers/settings.helper';
import { axiosBaseQuery } from '../axiosBaseQuery';
import { VendorAction, VendorRequest, VendorResponse } from './models/integrations.model';
import { httpMethod } from 'app/services/_httpClient';

export const integrationsApi = createApi({
    reducerPath: 'integrations-api',
    baseQuery: axiosBaseQuery({
        baseUrl: `${NAF_CONNECT_API_BASE_URL}/integrations/`,
    }),
    endpoints: (builder) => ({
        vendor: builder.mutation<VendorResponse, { request: VendorRequest; action: VendorAction }>({
            query: ({ request, action }) => {
                let url = '';
                switch (action) {
                    case 'referral':
                        url = 'vendor/clientReferral';
                        break;
                    case 'status':
                        url = 'vendor/clientStatus';
                        break;
                    case 'clients':
                        url = 'vendor/agentLogin';
                        break;
                    default:
                        break;
                }

                return {
                    url,
                    method: httpMethod.Post,
                    data: request,
                    headers: {
                        'X-API-Key': NAF_CONNECT_API_KEY,
                    },
                };
            },
        }),
    }),
});

export const { useVendorMutation } = integrationsApi;
