import { checkVersion } from './http-client.service';

export const checkVer = (): void => {
    checkVersion().then((serverVer) => {
        const ver = Number(document.getElementsByName('version-info')[0].getAttribute('content'));
        if (ver < serverVer) {
            window.location.reload();
        }
    });
};
