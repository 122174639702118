import { ReactElement, useContext } from 'react';
import { useTemplate } from 'app/shared/hooks/use-template';
import { EnvContext } from 'app/contexts/environment/environment.context';

import './terms-of-use.css';

export const TermsOfUse = (): ReactElement => {
    const env = useContext(EnvContext);
    const { TEMPLATE_GUID_TERMS_OF_USE = '' } = env;

    const { templateContent } = useTemplate(TEMPLATE_GUID_TERMS_OF_USE);

    return <div dangerouslySetInnerHTML={{ __html: templateContent }} />;
};
