import { apiRequest, getRealEstateAgentId, httpMethod } from '../_httpClient';
import { ApiBaseResponse, ServiceResponse } from '../_httpClient/models/http-client.model';
import { PreApprovalRequest, PreApprovalResponse } from './models';

export const getPreApprovalLetter = (
    payload: PreApprovalRequest
): ServiceResponse<ApiBaseResponse<PreApprovalResponse>> => {
    const endpointUrl = `/realEstateAgents/${getRealEstateAgentId()}/deals/${
        payload.dealId
    }/loans/${payload.loanId}/preApprovals`;

    return apiRequest<PreApprovalRequest, ApiBaseResponse<PreApprovalResponse>>(
        endpointUrl,
        httpMethod.Post,
        payload
    ).then((response) => {
        if ('isAxiosError' in response) return null;

        return response;
    });
};

export const downloadPreApprovalLetterNotification = (
    id: string | undefined,
    dealId: string,
    loanId: string,
    state: string
): ServiceResponse<ApiBaseResponse<boolean>> => {
    const endpointUrl = `/realEstateAgents/${getRealEstateAgentId()}/deals/${dealId}/loans/${loanId}/preApprovals/${id}/downloads`;
    return apiRequest<string, ApiBaseResponse<boolean>>(endpointUrl, httpMethod.Post, state).then(
        (response) => {
            if ('isAxiosError' in response) return null;

            return response;
        }
    );
};
