import { ReactElement, useContext } from 'react';
import { useTemplate } from 'app/shared/hooks/use-template';
import { EnvContext } from 'app/contexts/environment/environment.context';

export const ElectronicConsentAgreement = (): ReactElement => {
    const env = useContext(EnvContext);
    const { TEMPLATE_GUID_ELECTRONIC_CONSENT = '' } = env;

    const { templateContent } = useTemplate(TEMPLATE_GUID_ELECTRONIC_CONSENT);

    return <div dangerouslySetInnerHTML={{ __html: templateContent }} />;
};
