import { RawAxiosRequestHeaders } from 'axios';
import { httpMethod, httpRequest } from '../_httpClient';
import { ServiceResponse } from '../_httpClient/models/http-client.model';
import { Announcement } from './models';
import { NAF_CONNECT_API_KEY } from 'app/shared/helpers/settings.helper';

export const getAnnouncements = (): ServiceResponse<Array<Announcement>> => {
    const endpointUrl = '/announcements';
    const headers: RawAxiosRequestHeaders = {
        'Content-Type': 'application/json',
        'X-API-Key': `${NAF_CONNECT_API_KEY}`,
    };

    return httpRequest<null, Array<Announcement>>(endpointUrl, httpMethod.Get, headers, null).then(
        (response) => {
            if ('isAxiosError' in response) return null;

            return response;
        }
    );
};
