import './index.scss';

import { StrictMode } from 'react';

import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import App from './app/App';
import theme from './app/shared/themes/app.theme';
import { store } from './app/store/store';
import { init as initFullStory } from '@fullstory/browser';
import { GA_MEASUREMENT_ID } from 'app/shared/helpers/settings.helper';
import { BeforeInstallPromptEvent } from 'app/shared/hooks/use-pwa.hook';
import { EnvProvider } from 'app/contexts/environment/environment.context';
import { checkVer } from 'app/services/_httpClient/http-client.helper';
import { UIActionsProvider } from 'app/contexts/ui-actions/ui-actions.context';

declare global {
    interface Window {
        beforeInstallPromptEvent: BeforeInstallPromptEvent | undefined;
    }
}

checkVer();

initFullStory({ orgId: 'K2T09' });

ReactGA.initialize(GA_MEASUREMENT_ID);

window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    const deferredPrompt = e as BeforeInstallPromptEvent;
    window.beforeInstallPromptEvent = deferredPrompt;
});

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js?v=1.2');
}

const container = document.getElementById('root');
if (!container) throw new Error('container not found!');

const root = createRoot(container);

root.render(
    <EnvProvider>
        <StrictMode>
            <StyledEngineProvider injectFirst>
                <Provider store={store}>
                    <BrowserRouter>
                        <ThemeProvider theme={theme}>
                            <UIActionsProvider>
                                <App />
                            </UIActionsProvider>
                        </ThemeProvider>
                    </BrowserRouter>
                </Provider>
            </StyledEngineProvider>
        </StrictMode>
    </EnvProvider>
);
