import { ReactElement } from 'react';
import classnames from 'classnames';
import styles from './custom-circular-progress.module.scss';

import { Color } from 'app/shared/enums';

import { CircularProgress, createTheme, ThemeProvider } from '@mui/material';

interface CustomCircularProgressProps {
    size?: number | string;
    inline?: boolean;
    className?: string;
}

const theme = createTheme({
    palette: {
        primary: {
            main: Color.marinerBlue,
        },
    },
});

const CustomCircularProgress = ({
    size,
    inline,
    className,
}: CustomCircularProgressProps): ReactElement => (
    <ThemeProvider theme={theme}>
        {inline ? (
            <CircularProgress color="primary" size={size} />
        ) : (
            <div className={classnames(styles.indicator, { [className as string]: className })}>
                <CircularProgress color="primary" size={size} />
            </div>
        )}
    </ThemeProvider>
);

CustomCircularProgress.defaultProps = { size: undefined, inline: false, className: undefined };

export default CustomCircularProgress;
