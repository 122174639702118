import { ReactNode } from 'react';

import { Tooltip } from '@mui/material';

import CirclesLockIcon from '../components/svg-icons/circles-lock-icon.component';
import GreyCircleXIcon from '../components/svg-icons/grey-circle-x-icon.component';
import IconInfoIcon from '../components/svg-icons/icon-info-icon.component';
import { Color, Milestone } from '../enums';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

export const selectConditionText = (
    milestone: number | undefined,
    consent: boolean | undefined,
    isCanceled: boolean | undefined,
    isPrimary: boolean | undefined
): ReactNode => {
    let ConditionText = '';

    if (isCanceled) {
        return 'Loan Canceled';
    }

    switch (milestone) {
        case Milestone.ReferralSubmitted:
            return <div>Contacting Client</div>;
        case Milestone.PreApproved:
            if (isPrimary === false) {
                return (
                    <div>
                        Pre-Approval
                        <br />
                        Expired
                    </div>
                );
            }
            return (
                <div>
                    Client <br />
                    Pre-Approved
                </div>
            );
        case (milestone && Milestone.SubmittedToUw) || Milestone.ClearedToClose:
            if (consent === false) {
                return (
                    <div>
                        Outstanding Conditions <br />
                        Not Available
                    </div>
                );
            }
            ConditionText = 'Outstanding Conditions';
            return ConditionText;
        case Milestone.ConditionallyApproved:
            if (isPrimary === false) {
                ConditionText = 'Under Review';
                break;
            }
            ConditionText = 'Outstanding Conditions';
            return ConditionText;
        case Milestone.Funded:
            return <div>Outstanding Conditions</div>;
        case undefined:
            ConditionText = 'Processing Referral';
            break;
        default:
            ConditionText = 'Outstanding Conditions';
            break;
    }

    return ConditionText;
};

const conditionsTooltip =
    'Contacting Client: Collecting Client Information and pre qualification documents';

export const selectConditionsImage = (
    milestone: number | undefined,
    consent: boolean | undefined,
    isCanceled: boolean | undefined,
    isPrimary: boolean | undefined
): ReactNode => {
    if (isCanceled) {
        return <GreyCircleXIcon width={20} />;
    }
    if (milestone === Milestone.SubmittedToUw || milestone === Milestone.ClearedToClose) {
        if (consent === false) {
            return <CirclesLockIcon width={20} fill={Color.darkCharcoal} />;
        }
    }

    if (milestone === Milestone.ConditionallyApproved && isPrimary === false) {
        return <WarningRoundedIcon width={20} sx={{ color: Color.warningYellow }} />;
    }

    if (milestone === Milestone.PreApproved && isPrimary === false) {
        return <WarningRoundedIcon width={20} sx={{ color: Color.warningYellow }} />;
    }

    return (
        <Tooltip title={conditionsTooltip} placement="top" arrow>
            <span>
                <IconInfoIcon width={20} fill={Color.marinerBlue} />
            </span>
        </Tooltip>
    );
};
