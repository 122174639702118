export const generatePassword = (passwordLength = 8): string => {
    const charsetLower = 'abcdefghijklmnopqrstuvwxyz';
    const charsetUpper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charsetNumbers = '0123456789';
    const charsetSpecial = '!@#$%^&*()';
    const allCharset = charsetLower + charsetUpper + charsetNumbers + charsetSpecial;

    let password = '';
    password += charsetLower.charAt(Math.floor(Math.random() * charsetLower.length));
    password += charsetUpper.charAt(Math.floor(Math.random() * charsetUpper.length));
    password += charsetNumbers.charAt(Math.floor(Math.random() * charsetNumbers.length));
    password += charsetSpecial.charAt(Math.floor(Math.random() * charsetSpecial.length));

    for (let i = 4; i < passwordLength; ++i) {
        password += allCharset.charAt(Math.floor(Math.random() * allCharset.length));
    }

    return password
        .split('')
        .sort(() => 0.5 - Math.random())
        .join('');
};
