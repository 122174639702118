import { useContext, useEffect, useState } from 'react';
import { EnvContext } from 'app/contexts/environment/environment.context';

const getTokenFromUrl = (): string => {
    const url = window.location.href;
    const tokenKey = 'id_token';
    const regExp = `[?#&]${tokenKey}=([^&]*)`;
    const match = url.match(regExp);
    return match ? match[1] : '';
};

interface PingAuth {
    pingToken: string | null;
}

const usePingAuth = (): PingAuth => {
    const [pingToken, setPingToken] = useState<string | null>(null);
    const env = useContext(EnvContext);
    const { PING_AUTHORIZATION_ENDPOINT = '', PING_CLIENT_ID = '' } = env;
    const responseType = 'id_token';
    const scope = 'openid';

    const redirectToPingIdentity = (): void => {
        const redirectUri = window.location.origin + window.location.pathname;
        const nonce = Math.floor(Math.random() * Date.now());
        const url = `${PING_AUTHORIZATION_ENDPOINT}?response_type=${responseType}&client_id=${PING_CLIENT_ID}&redirect_uri=${redirectUri}&scope=${scope}&nonce=${nonce}`;
        window.location.replace(url);
    };

    const authorize = (): void => {
        const token = getTokenFromUrl();

        if (token) {
            setPingToken(token);
        } else {
            redirectToPingIdentity();
        }
    };

    useEffect(() => {
        if (PING_AUTHORIZATION_ENDPOINT && PING_CLIENT_ID) {
            authorize();
        }
    }, [PING_AUTHORIZATION_ENDPOINT, PING_CLIENT_ID]);

    return {
        pingToken,
    };
};

export default usePingAuth;
