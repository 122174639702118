import { searchLoanOfficers } from 'app/services';
import { SearchLoanOfficersRequest } from 'app/services/loan-officers/models/search-loan-officers.model';
import { AsyncRequestStatus } from 'app/shared/enums';
import { PreferredLoanOfficer } from 'app/shared/models';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface LoanOfficersState {
    loanOfficers: Array<PreferredLoanOfficer>;
    statusSearchLoanOfficers: AsyncRequestStatus;
}

const initialState: LoanOfficersState = {
    loanOfficers: [],
    statusSearchLoanOfficers: AsyncRequestStatus.Idle,
};

export const searchLoanOfficersAsync = createAsyncThunk(
    'loanOfficers/searchLoanOfficers',
    async ({
        stateLicensed,
        searchInput,
        includePreferredLoanOfficers,
    }: SearchLoanOfficersRequest) => {
        const response = await searchLoanOfficers(
            stateLicensed,
            searchInput,
            includePreferredLoanOfficers
        );
        return response;
    }
);

export const loanOfficersSlice = createSlice({
    name: 'loanOfficers',
    initialState,
    reducers: {
        resetLoanOfficers(state) {
            state.loanOfficers = [];
        },
    },
    extraReducers: (builder) => {
        builder
            // Search Loan Officers
            .addCase(searchLoanOfficersAsync.pending, (state) => {
                state.statusSearchLoanOfficers = AsyncRequestStatus.Pending;
            })
            .addCase(searchLoanOfficersAsync.fulfilled, (state, action) => {
                state.statusSearchLoanOfficers = AsyncRequestStatus.Fulfilled;

                state.loanOfficers = action?.payload?.data?.data ?? [];
            })
            .addCase(searchLoanOfficersAsync.rejected, (state) => {
                state.statusSearchLoanOfficers = AsyncRequestStatus.Rejected;
            });
    },
});

export const loanOfficersSelector = (state: RootState): Array<PreferredLoanOfficer> =>
    state.loanOfficers.loanOfficers;

export const statusSearchLoanOfficersSelector = (state: RootState): AsyncRequestStatus =>
    state.loanOfficers.statusSearchLoanOfficers;

export const { resetLoanOfficers } = loanOfficersSlice.actions;

export default loanOfficersSlice.reducer;
