export const isIOS = (): boolean => {
    const { userAgent } = navigator;
    if (!userAgent) return false;

    const iOSDevices = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
    ];

    // Check if any of the iOS device strings are present in the user agent
    const isIOSDevice = iOSDevices.some((device) => userAgent.includes(device));

    // Check for iPad on iOS 13
    const isIOS13iPad = userAgent.includes('Mac') && 'ontouchend' in document;

    return isIOSDevice || isIOS13iPad;
};

export const isIPhone = (): boolean => {
    const { userAgent } = navigator;
    if (!userAgent) return false;

    // Check if the user agent string includes 'iPhone'
    return userAgent.includes('iPhone');
};

export const isAndroid = (): boolean => {
    const { userAgent } = navigator;
    if (!userAgent) return false;

    return /Android/i.test(userAgent);
};
