import { httpMethod, httpRequest } from '../_httpClient';
import { FeatureFlag } from 'app/shared/models/feature-flag/feature-flag.model';

import { queryParametersToString } from 'app/shared/helpers';
import { FeatureFlagRequest } from './models';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFeatureFlag = async (request: FeatureFlagRequest): Promise<any> => {
    let endpointUrl = `featureFlags/${request.name}`;
    let paramsString = '';
    const headers = {
        'Content-Type': 'application/json',
    };

    if (request.queryParameters) {
        paramsString = queryParametersToString(request.queryParameters);
        endpointUrl = `featureFlags/${request.name}?${paramsString}`;
    }

    const payload = '';

    const response = httpRequest<string, FeatureFlag>(
        endpointUrl,
        httpMethod.Get,
        headers,
        payload
    ).then((response) => response);

    return response;
};
