import { ReactElement, useContext } from 'react';
import { useTemplate } from 'app/shared/hooks/use-template';
import { EnvContext } from 'app/contexts/environment/environment.context';

import './co-branding-agreement.css';

export const CoBrandingAgreement = (): ReactElement => {
    const env = useContext(EnvContext);
    const { TEMPLATE_GUID_CO_BRANDING_AGREEMENT = '' } = env;

    const { templateContent } = useTemplate(TEMPLATE_GUID_CO_BRANDING_AGREEMENT);

    return <div dangerouslySetInnerHTML={{ __html: templateContent }} />;
};
